import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload"
export default class extends Controller {
  static targets = ['fileField', 'idField', 'destroyField', 'remove', 'preview']
  

  initialize() {
    if (this.hasIdFieldTarget) {
      this.idFieldTarget.setAttribute('disabled', 'true')
    }
    if (this.hasDestroyFieldTarget) {
      this.destroyFieldTarget.setAttribute('disabled', 'true')
    }
  }

  connect () {
  }


  remove() {
    this.element.removeChild(this.removeTarget)
    this.element.removeChild(this.fileFieldTarget)
    this.element.removeChild(this.previewTarget)
    this.idFieldTarget.removeAttribute('disabled')
    this.destroyFieldTarget.removeAttribute('disabled')
    this.destroyFieldTarget.setAttribute('value', 'true')
  }

}
