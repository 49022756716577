/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
  $(document).on('turbo:load', function() {  


      // Set the correct maximums for the relevant test types and company limits
    $("#test_profile_test_type").change(() => Maximums.check());

    $('#ivr_scripts').change(function() {
      const current_value = $('#ivr_script_ids').val();
      const current_profile_value = $('#test_profile_ivr_script_ids').val();
      const new_id_value = $('#ivr_scripts').val();
      const new_ivr_name = $("#ivr_scripts").children("option").filter(":selected").text();
      $('#ivr_script_ids').val(current_value + " " + new_id_value + ",");
      $('#test_profile_ivr_script_ids').val(current_profile_value  + " " + $('#ivr_scripts').val() + ",");
       $('#selected_ivrs').append('<li class="'+  new_id_value  + '"><span class="medium"><i class="delete_li fi-trash"></i>    - </span>' + new_ivr_name + '</li>');
    });
    $('#selected_ivrs').on('click','i.delete_li', function() {
      const the_li = $(this).parent().parent();
      the_li.addClass('hidden');
      const remove_this_id = the_li.attr('class');
      const current_id_list_values = $('#ivr_script_ids').val();
       $('#ivr_script_ids').val(current_id_list_values.replace(remove_this_id,''));
    });
    $('.endpoint_form_reveal').click(() => $('.hidden_endpoint_form').removeClass('hidden'));

      // Set corect maximum on page edit.
    if (($("#test_profile_test_type").val() === "Load Testing") && ($('select.protocol_selector').val() !== "IN COUNTRY")) {
      company_limit = $("#company_test_limit").data("value");
    } else if ($('select.protocol_selector').val() !== "IN COUNTRY") {
      company_limit = 10;
    } else {
      company_limit = 1;
    }

    // return $("#test_profile_max_concurrent_calls").knob({
    //       "fgColor":"#0277bd",
    //       "inputColor":"#01579b",
    //       "displayPrevious":'true',
    //       "stopper":"true",
    //       "max": company_limit
    //     });
  });
