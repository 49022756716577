$(document).on("turbo:load", function() {

$(".toggle-password").click(function() {
      $(this).toggleClass("fi-eye fi-alert");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
});

$("#get_import_ivrs").click(function() {
  $("#discovery-in-progress").removeClass('hidden');
})

$("#integration_vendor").change(function() {
  revealVendorOptions($("#integration_vendor").val())
})

if ($('#integration_vendor').is(':visible')) {
  revealVendorOptions($("#integration_vendor").val())
}

function revealVendorOptions(selected_option) {
  $newoptions = ''
  let existing = $('select#integration_api_region_url').val()
  if(selected_option === 'purecloud') {
    $('label[for="integration_api_key"]').html('API Key');
    $('label[for="integration_secret_key"]').html('Secret Key');
    $newoptions = '<option selected="selected" value="mypurecloud.com">mypurecloud.com</option><option value="mypurecloud.com.au">mypurecloud.com.au</option><option value="mypurecloud.ie">mypurecloud.ie</option><option value="mypurecloud.jp">mypurecloud.jp</option>';
  } else if (selected_option === 'five9') {
    $('label[for="integration_api_key"]').html('Username');
    $('label[for="integration_secret_key"]').html('Password');
    $newoptions = '<option value="api.five9.com">api.five9.com</option><option value="api.five9.eu">api.five9.eu</option><option value="api.five9.ca">api.five9.ca</option>';
  }

  $('select#integration_api_region_url').html($newoptions);
  $('select#integration_api_region_url').val(existing)
  $("#form_options").show();
}

});
