import { Controller } from "@hotwired/stimulus"
import 'jstree'

// Connects to data-controller="mapper"
export default class extends Controller {
  static targets = ['container', 'stepEdit']

  connect() {
    this.populateTree()
  }

  refresh() {
    this.populateTree()
  }

  populateTree() {
    const child_id = this.containerTarget.dataset.childId
    $.ajax({
      url: $(location).attr('href').split('?')[0] + '/tree.json?' + (new Date).getTime(),
      data: { format: 'json'
    },
      error(jqXHR, textStatus, errorThrown) {
        console.log(errorThrown);
      },
      success(data) {
        $('#jstree_div')
          .on('changed.jstree', function(e, data) {
            if (data.node !== undefined) {
             $.get('/control_centre/ivr_mapper/child_details/' + data.node.id);
            }
          })
          .on('loaded.jstree', function(e, data) {  
            if (child_id != null) {
              $('#jstree_div').jstree()._open_to( child_id );
              $("a#" + child_id + "_anchor").trigger("click");
            } else {
              $("a.jstree-anchor").trigger("click");
            }
            $("a[child_actions='1']").addClass('text-red-300');
           $("a[needs_action='1']").addClass('text-red-500');
            $("a[recommended='1']").removeClass('text-red-500').addClass('text-green-500');
          })
          .on('after_open.jstree', function(e, data) {
            $("a[child_actions='1']").addClass('text-red-300');
           $("a[needs_action='1']").addClass('text-red-500');
            $("a[recommended='1']").removeClass('text-red-500').addClass('text-green-500');
          })
          .jstree({ 
            "core" : {    
              "themes" : {
                "theme" : "default",
                "dots" : true,
                "icons" : false
              },
              'data' : [data]} 
          });
      },
      type: 'GET'
    });  

    $('#mapper_children').on('click', '.open_jstree', () => $('#jstree_div').jstree("open_all"));

    $('#mapper_children').on('click', '.close_jstree', () => $('#jstree_div').jstree("close_all"));



  }

  toggle_edit () {
    this.stepEditTarget.classList.toggle('hidden')
  }

  change_action (event) {
    event.preventDefault()
    const this_val = $('#mapper_options').val();
    if (this_val === 'refresh') {
     $('#refresh_btn').trigger('click');
    } else if (this_val === 'retry_this') {
      if (confirm("This will lose all child branches. Do you want to proceed?")) {
        $('#ivr_mapper_status').val('ready');
        $('#analyse_btn').trigger('click');
      } else {
        $('#mapper_options').val('');
      }
    } else if (this_val === 'reenable') {
      $('#ivr_mapper_status').val('analysed');
     $('#update_name_btn').trigger('click');
    } else if (this_val === 'make_call') {
      $('#ivr_mapper_status').val('make_call');
     $('#analyse_btn').trigger('click');
    } else if (this_val === 'duplicate') {
      $('#duplicate_btn').trigger('click');
      return window.location = $('#duplicate_btn').val();
    } else if (this_val === 'save_transcript') {
     $('#analyse_btn').trigger('click');
    } else if (this_val === 're_transcribe') {
      $('#ivr_mapper_status').val('call_in_progress');
     $('#analyse_btn').trigger('click');
    } else if (this_val === 'analyse') {
      $('#ivr_mapper_status').val('ready_to_analyse');
     $('#analyse_btn').trigger('click');
    } else if (this_val === 'play_call') {
     $('.call_audio').trigger('click');
    } else if (this_val === 'play_transcript') {
     $('.transcript_audio').trigger('click');
    } else if (this_val === 'direct_discovery') {
     $('#direct_discovery').trigger('click');
    } else if (this_val === 'add_branch') {
     $('#new_child_branch').show();
    } else if (this_val === 'monitor_this') {
      return window.location = $('#monitor_this_btn').val();
    } else if (this_val === 'export') {
      return window.location = $('#export_btn').val();
    } else if (this_val === 'end_branch') {
     $('#end_branch_btn').trigger('click');
    } else if (this_val === 'abandon_branch') {
     $('#abandon_branch_btn').trigger('click');
    }
  }
}
