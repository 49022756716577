/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbo:load', function() {
        $('#ivr_wrapper').on( 'change', '.intent_order',  function() {
          if ($(this).val() === "3") {
             $(this).parent().parent().find('.utterance_select_div').removeClass('hidden');
          } else { 
             $(this).parent().parent().find('.utterance_select_div').addClass('hidden');
          }
        });
        $('#ivr_wrapper').on( 'change', '.utterance_select',  function() {
           $(this).parent().find('.utterance_id_hidden').val($(this).val());
        });
         $('#ivr_wrapper').on( 'click', '.show_steps',  function() {
          const parent = $(this).parent();
          const step_container = $(parent).find('.steps_container');
          if ($(this).text() === 'Hide Steps') {
            $(this).text('See Steps');
            return $(step_container).addClass('hidden');
          } else {
            $(this).text('Hide Steps');
            
            if ($(step_container).data('populated') !== true) {
              
              $(this).parent().find('.small-loader').removeClass('hidden');
              const ivr_id = $(parent).parent().parent().find('.ivr_script_id_hidden').val();
              $.ajax('/test_cases/add_ivr_validation_steps/'+ ivr_id + ".json?" + (new Date).getTime() , {
                type: 'GET',
                dataType: 'json',
                contentType: "application/json",
                data: {
                },
                error(jqXHR, textStatus, errorThrown) {
                     console.log(`AJAX Error: ${textStatus}`);
                  },
                success(response) {
                  $(step_container).find('.ivr_step_container').each((index, element) => {
                    let value_text;
                    const ivr_step_type = response.items[index]['ivr_step_type'];
                    if (ivr_step_type === 'Send Utterance') {
                        value_text = response.items[index]["intent_tags"];
                    } else {
                        value_text = response.items[index]["value"];
                      }
                    $(element).prepend(`\
<div class='mt-5 font-bold'> \
<div class=''> \
<h5 class='text-primary-500'>${response.items[index]['ivr_step_description']}</h5> \
</div> \
</div> \
<div class='flex space-x-40'> \
<div class='text-info-500'> \
<label>STEP TYPE:</label> ${ivr_step_type} \
</div> \
<div class='text-info-500'> \
<label>VALUE:</label> ${value_text} \
</div> \
<div class='text-info-500'> \
<label>AUDIO FILE:</label> ${response.items[index]['audio_file_name']} \
<a class='play_audio_link' href='/play_audio/${response.items[index]['audio_file_id']}'> \
<i class='edit-audio-btn fi-page-edit'></i> \
</a> \
</div> \
</div> \
<br>\
`);                   
                    $(element).find('input.audio_file_id_hidden_field').val(response.items[index]["audio_file_id"]);
                    if (["DTMF", "Initial Response", "Send Audio"].indexOf(ivr_step_type) !== -1) {
                      $(element).find("div.response_time_div").removeClass('hidden');
                    }
                    if (["Send Audio", "Receive Audio", "Receive Audio (multi)"].indexOf(ivr_step_type) !== -1) {
                      $(element).find('div.verify_div').removeClass('hidden');
                      $(element).find('.play_audio_link').removeClass('hidden');
                    }
                    if (["Receive Audio"].indexOf(ivr_step_type) !== -1) {
                      $(element).find('div.receive_audio_div').removeClass('hidden');
                    }
                    if (["Send Utterance"].indexOf(ivr_step_type) !== -1) {
                      $(element).find('div.utterance_div').removeClass('hidden');
                      const select = $(element).find('.utterance_select');
                      $('option', select).remove();
                      $.each(response.items[index]['utterance_options'], (val, text) => select.append("<option value=" + text[1] + ">" + text[0] + "</option>"));
                      select.val($(element).find('.utterance_id_hidden').val());
                      
                      if ($(element).find('.intent_order').val() === '3') {
                         $(element).find('.utterance_select_div').removeClass('hidden');
                      } else { 
                         $(element).find('.utterance_id_hidden').val('');
                      }
                    }
                  });
                      

                  $(step_container).data('populated', true);
                   $(parent).find('.small-loader').addClass('hidden');
                }
              }
              );
            }
             $(step_container).removeClass('hidden');
          }
        });
});
