import { Controller } from "@hotwired/stimulus"

const funcional_test_type     = 1;
const monitoring_test_type    = 2;    
const load_test_type          = 3;
const hidden_class            = 'hidden';

// Connects to data-controller="validate-form"
export default class extends Controller {
  static targets = ["form", "output", "retries", "protocol"];  
  static values = { url: String };
  
  connect() {              
    let test_type = parseInt(document.getElementById("test_case_test_type").value);
    this.setRangeLimits(test_type);
    this.setProtocols(test_type);
    this.setCountryBreakout(test_type);
  }

  valid_form_alert() {    
    var scrollDiv = document.getElementById("buttons").offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
  }
  
  updateCriteria(e) {
    let test_type = parseInt(document.getElementById("test_case_test_type").value);

    this.setRangeLimits(test_type);
    this.setProtocols(test_type);
    this.setCountryBreakout(test_type);
  } 

  setCountryBreakout(test_type) {  
    var frequency_holder          = document.getElementById('frequency-holder'); 
    var retry_dial                = document.getElementById('retry-dial'); 
    var test_case_breakout_id     = document.getElementById('test_case_breakout_id'); 
    var country_selector          = document.getElementById('country_selector');
    var incountry_selector        = document.getElementById('incountry_selector');
    var call_selector             = document.querySelectorAll('.call_distribution');



    switch (test_type) {
      case monitoring_test_type:        
        if (frequency_holder) { frequency_holder.classList.remove(hidden_class); }
        if (retry_dial) {retry_dial.classList.remove(hidden_class); }          
        if (test_case_breakout_id) {test_case_breakout_id.classList.remove(hidden_class); }
        call_selector.forEach(function(element) {
          element.classList.add(hidden_class);
        });
        break;
      case funcional_test_type:        
        if (frequency_holder) { frequency_holder.classList.add(hidden_class); }
        if (retry_dial) {retry_dial.classList.add(hidden_class); }        
        if (test_case_breakout_id) {test_case_breakout_id.classList.remove(hidden_class); }
        call_selector.forEach(function(element) {
          element.classList.add(hidden_class);
        }); 
        break;
      case load_test_type:        
        if (frequency_holder) { frequency_holder.classList.add(hidden_class); }
        if (retry_dial) {retry_dial.classList.add(hidden_class); }
        if (country_selector) {country_selector.value = ''; }
        if (incountry_selector) {incountry_selector.classList.add(hidden_class); }
        if (test_case_breakout_id) {test_case_breakout_id.value = ''; }

        call_selector.forEach(function(element) {
          element.classList.remove(hidden_class);
        }); 
        break;
      default:        
        break;
     }
  }

  setProtocols(test_type) {    
    const retries_div             = document.getElementById("retries");
    const criteria_holder         = document.getElementById("success-criteria-holder");
    const incountry_selector      = document.getElementById("incountry_selector");
    const test_case_source_ddi    = document.getElementById("test_case_source_ddi");    

    const protocol_element        = this.protocolTarget;
    const protocol_option         = protocol_element.querySelector('option[value="IN COUNTRY"]');
    
    switch(test_type) {

      case funcional_test_type:
        if (!protocol_option) {
          this.addOptiontoElement(protocol_element, 'IN COUNTRY', 'IN COUNTRY');          
        }

        // Hide Retry Slider        
        retries_div.classList.add(hidden_class);
        // success_no.checked = true;
        break;
      
      case monitoring_test_type:        
        if (!protocol_option) {
          this.addOptiontoElement(protocol_element, 'IN COUNTRY', 'IN COUNTRY');
        }

        // Show Retry Slider
        criteria_holder.classList.remove(hidden_class);
        retries_div.classList.remove(hidden_class);
        break;
      
      case load_test_type:       
        // Remove In Country Option from Protocol List
        protocol_element.options.remove(2);

        // Hide country selector
        incountry_selector.classList.add(hidden_class);

        // Enable Source DDI Field
        test_case_source_ddi.removeAttribute('disabled');

        // Hide Retry Slider        
        retries_div.classList.add(hidden_class);
        // success_no.checked = true;
        break;

      default:
        if (protocol === false) {
          protocol_element.add(document.createElement("IN COUNTRY"));
        }        

        // Hide Retry Slider        
        retries_div.classList.add(hidden_class);
        // success_no.checked = true;
        break;
    }
  }

  addOptiontoElement(element, value, textContent) {
    var newOption = document.createElement('option');
    newOption.value = value;
    newOption.textContent = textContent;
    element.appendChild(newOption);
  }

  setRangeLimits(test_type) {
    const protocol                              = this.protocolTarget.selectedOptions[0].value    
    const max_no_calls                          = document.getElementById('max_no_calls')
    const max_no_calls_range                    = document.getElementById('maxNoCallRange')  
    const call_no_min_limit                     = document.getElementById('callNoMinLimit')
    const call_no_max_limit                     = document.getElementById('callNoMaxLimit')
    const max_concurrent_calls                  = document.getElementById('max_concurrent_calls')
    const max_concurrent_call_range             = document.getElementById('maxConcurrentCallRange')
    const concurrent_call_max_limit             = document.getElementById('concurrentCallMaxLimit')

    const maximum_concurrent_calls_base         = document.getElementById('max_concurrent_calls').dataset.maxrangebase

    // Update the ramp up time
    if ((test_type === 3)) {

      // Set Maximum to Base Limit
      max_no_calls.min                        = 0
      max_no_calls_range.min                  = 0
      max_no_calls.max                        = parseInt(maximum_concurrent_calls_base)
      max_no_calls_range.max                  = parseInt(maximum_concurrent_calls_base)
      call_no_min_limit.innerHTML             = 0
      call_no_max_limit.innerHTML             = parseInt(maximum_concurrent_calls_base)
      var maximum_concurrent_calls            = parseInt(maximum_concurrent_calls_base)

      // Update Default Range
      max_concurrent_calls.dataset.maxrange   = maximum_concurrent_calls

      // Calculate Limit
      var new_maximum_concurrent_calls        = parseInt(maximum_concurrent_calls)

      // Set to int
      var max_concurrent_calls_max            = parseInt(max_concurrent_calls.max)

      // Stop updating max value if max is already set
      if (max_concurrent_calls_max < new_maximum_concurrent_calls || max_concurrent_calls_max == 1) {
        max_concurrent_calls.max             = new_maximum_concurrent_calls
        max_concurrent_call_range.max        = new_maximum_concurrent_calls      
        concurrent_call_max_limit.innerHTML  = new_maximum_concurrent_calls               
      }
    
    } else if (protocol !== "IN COUNTRY") {      

      // Set Maximum to Base Limit
      max_no_calls.min                      = 1
      max_no_calls_range.min                = 1
      var maximum_concurrent_calls          = parseInt(max_concurrent_calls.dataset.maxrange = 10)
      call_no_max_limit.innerHTML           = 100
      max_no_calls_range.max                = 100
      // Update Limits
      max_concurrent_calls.max              = 10
      max_concurrent_call_range.max         = 10
      concurrent_call_max_limit.innerHTML   = 10
      
      // Check if Value greater than max
      if (max_concurrent_calls.value > maximum_concurrent_calls) {
        max_concurrent_calls.value = maximum_concurrent_calls
      }

    } else {      

      // Set Maximum to Base
      max_no_calls.min                      = 1
      max_no_calls_range.min                = 1
      call_no_max_limit.innerHTML           = 1
      max_no_calls_range.max                = 1
      var maximum_concurrent_calls          = parseInt(max_concurrent_calls.dataset.maxrange = 1)

      // Update Limit
      // TO DO Change max back to 1
      max_concurrent_calls.value            = 1
      max_concurrent_calls.max              = 2
      max_concurrent_call_range.max         = 2
      concurrent_call_max_limit.innerHTML   = 1       

      // Check if Value greater than max
      if (max_concurrent_calls.value > maximum_concurrent_calls) {
        max_concurrent_calls.value = maximum_concurrent_calls
      }
    }
  }

}
