import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="dashboard-panel-component"
export default class extends Controller {
  connect() {
   this.load()
  }

  edit() {
    let form_frame = document.querySelector('turbo-frame#edit_panel')
    if (form_frame) {
      form_frame.src = `/dashboard_panels/${this.element.dataset.number}/edit/`
    }
  }

  load() {
    // Load the show method for the dashboard panel in question
    let chart_frame = document.querySelector(`turbo-frame#panel_${this.element.dataset.number}`)
    if (chart_frame) {
      chart_frame.src = `/dashboard_panels/${this.element.dataset.number}`
    }
    const timer = this.element.dataset.timervalue || 60000
    setTimeout(() => { this.load() }, timer);
  }


  disconnect(e) {

  }
}