import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="activation"
export default class extends Controller {
  static targets = ['editable','select', 'version_link', 'make_active']
  connect() {
    this.formChanged = false
   
    for (let i of this.editableTargets) {
      i.addEventListener('input', (event) => {
        this.formChanged = true
      });
    }
  }

  async versionSelect(e) {
    this.version_linkTarget.href = this.version_linkTarget.href.replace("REPLACE_ME", e.target.value);
    if (this.formChanged) { 
      alert("Save changes before making active.")
    } else {
      this.version_linkTarget.click()
    }
  }

  makeActive(e) {
    if (this.formChanged) {
      e.stopImmediatePropagation()
      e.preventDefault()
      alert("Save changes before making active.")
    }
  }
}
