$(document).on("turbo:load", function() {
  const modals = document.getElementsByClassName('modal')


  if (modals) {
    Array.from(modals).forEach(element => {
      const revealModal = element.parentNode.querySelector ('.revealModal')
      element.addEventListener('click', () =>{
        revealModal.classList.toggle('hidden')
        revealModal.classList.add('flex')
  
      revealModal.addEventListener('click', (e) =>{
        if (e.target.classList.contains('z-50')) {
        revealModal.classList.remove('flex')
        revealModal.classList.add('hidden') 
        }
      })
      })
    });
  }
 
})     