import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reports"
export default class extends Controller {
  static targets = ['timePeriod', 'scheduled','scheduler', 'datePicker']
  reportId = this.element.dataset.id

  connect() {
    if (this.hasTimePeriodTarget) {
      this.ToggleDateSelect()
      this.ToggleScheduler()
    }
  }

  ReportTestCasesUpdate() {
    const params = new URLSearchParams()
    params.set('id', this.reportId)
    params.set('type', $('#report_test_type').val())
    params.set('selected_user_id', $('#report_selected_user_id').val())
    params.set('limit_date', $('#report_limit_date').val())
 
    let test_case_frame = document.querySelector('turbo-frame#test_cases')
    if (test_case_frame) {
     test_case_frame.src = `/control_centre/reports/return_test_cases?${params.toString()}`
    }
   };


   ShowLoader() {
    $('div#report_download').html('<div class="small-loader"></div>');
   }


   ToggleDateSelect() {
    if (this.timePeriodTarget.value == "Custom Range") {
      this.datePickerTarget.classList.remove('hidden');
    } else {
      this.datePickerTarget.classList.add('hidden');
    }
   }

   ToggleScheduler() {
    if(this.scheduledTarget.checked) {
      this.schedulerTarget.classList.remove('hidden');
    } else {
      this.schedulerTarget.classList.add('hidden');
    }
   }

}
