import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="clock"
export default class extends Controller {
  static targets = ['clock']

  connect() {
    this.refreshClock()
    setInterval(() => this.refreshClock(), 60000)    
  }

  refreshClock() {
    if (!this.hasClockTarget) {
      return
    }
    let date = new Date();
    let options = {timeZone: this.clockTarget.dataset.timezone, day: "numeric", month: "long", hour: '2-digit', minute:'2-digit', timeZoneName: 'short' };
    let timeInZone = date.toLocaleString(['en-GB'], options);
    // the following is only used to add the ordinal for the date.
    let split_date = timeInZone.split(" ")
    let date_part = split_date[0]
    let ordinalSuffix = this.addOrdinalSuffix(date_part);

    split_date[0] = date_part + ordinalSuffix
    this.clockTarget.innerHTML = split_date.join(" ")
  }

  addOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
  }

}