import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
  }

  toggle() {
    window.location = "/users/toggle_dark_mode"
  }

}
