import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ddi-field"
export default class extends Controller {
  static targets = ['orig', 'country', 'number', 'dnis']


  update_number () {
    let local = this.origTarget.value
    if (this.origTarget.value.charAt(0) === '0' && this.origTarget.value.charAt(1) !== '0') {
      local = this.origTarget.value.substring(1)
    }
    var country_code = ''

    if (this.countryTarget.value != '') {
      country_code =  this.splitCountryCode(this.countryTarget.options[this.countryTarget.selectedIndex].text)
    }
    
    this.numberTarget.value = country_code + local
    this.dnisTarget.value = this.numberTarget.value
  }

  splitCountryCode(value) {
    let str = value.split('+')
    str = str[str.length - 1]
    return str.replace(")", '')
  }
}
