document.addEventListener('turbo:load', function(event) {
  var settings = {};
  document.querySelectorAll('.audio_file, .selected_range, .tag_select ').forEach((el)=>{
    if (!el.tomselect) {
      new TomSelect(el,settings);
     }
  });
  
  
  if(document.getElementById("ivr_ivr_script_id")){
    if (!document.getElementById("ivr_ivr_script_id").tomselect) {
      new TomSelect('#ivr_ivr_script_id',settings);
    }
  } 

});

var updateLink = function (event) {
  let play_link = event.target.parentNode.parentNode.parentNode.querySelectorAll(".play_audio_link")[0]
  if (!play_link) {
    return
  }
  play_link.id = event.target.dataset.value
  play_link.href = '/play_audio/' + event.target.dataset.value + '?' + new Date().getTime()
}

document.addEventListener('turbo:frame-load', function(event) {
  var settings = {
    render:{
		item: function(data) {
      const div = document.createElement('div')

      div.innerText = data.text;
      div.className = "item"
      div.addEventListener('click', updateLink, false)
      return div
    }
    }
  }
  document.querySelectorAll('.audio_file, .selected_range, .tag_select ').forEach((el)=>{
     if (!el.tomselect && !el.classList.contains('ts-wrapper')) {
      new TomSelect(el,settings);
     }
  });


  if(document.getElementById("ivr_ivr_script_id")){
    if (!document.getElementById("ivr_ivr_script_id").tomselect) {
      new TomSelect('#ivr_ivr_script_id',settings);
    }
  } 


});

$(document).on("turbo:frame-render", function () {
  $('.multiSelect').multiSelect();
})