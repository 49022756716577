import multiSelect from "multiselect"

import "blueimp-file-upload/js/jquery.fileupload.js"
import "jquery.quicksearch/dist/jquery.quicksearch.min.js"

import Sortable  from "sortablejs"
import flatpickr from "flatpickr"

window.multiSelect = multiSelect
window.Sortable = Sortable
