import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="widgets"
export default class extends Controller {
  static targets = ["panel"];

  toggelArrow() {
    const panel1 = document.getElementById('panel1')
    const parent = ((panel1).parentNode)
    const expandPanelBtn = document.getElementById('expand_panel_btn')
    if (parent.classList.contains('flex-wrap')) {
       // Add Classes
       expandPanelBtn.classList.add('rotate-180')       
       // Remove Classes                
       expandPanelBtn.classList.remove('rotate-0')
       expandPanelBtn.classList.remove('order-last')
       this.toggelPanel()
    } else {
      // Add Classes
      expandPanelBtn.classList.add('rotate-0')      
      expandPanelBtn.classList.add('order-last')        
      // Remove Classes                  
      expandPanelBtn.classList.remove('rotate-180')
      // Toggle Panel
      this.toggelPanel()
    }
  }

  toggelPanel() {    
    // Select parent div           
    const panel1 = document.getElementById('panel1')
    const panel2 = document.getElementById('panel2')
    const parent = ((panel1).parentNode)
    const expandPanelBtn = document.getElementById('expand_panel_btn')
    var panelStatus = expandPanelBtn.dataset.panelStatus    
    
    if (panelStatus == 1) {       
      // Panel is expaned

      // Add Classes
      panel2.classList.add('w-96')
      parent.classList.add('flex')
      // Remove Classes
      parent.classList.remove('flex-wrap')      
      parent.classList.remove('flex-1')          
      parent.classList.remove('flex-wrap')        
      panel1.classList.remove('hidden')
      expandPanelBtn.dataset.panelStatus = 0      
    } else {
      // Panel is closed

      // Add Classes      
      parent.classList.add('flex-wrap')      
      parent.classList.add('flex-1')
      panel1.classList.add('hidden')      
      // Remove Classes      
      parent.classList.remove('flex')    
      panel2.classList.remove('w-96')
      expandPanelBtn.dataset.panelStatus = 1      
    }
  }

  filterByName(event) {
    const searchTerm = event.target.value.toLowerCase();
    this.panelTargets.forEach((el) => {
      const text = el.textContent.toLowerCase();
      if (text.includes(searchTerm)) {
          el.style.display = '';
      } else {
          el.style.display = 'none';
      }
    });
  }
}
