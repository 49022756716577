document.addEventListener('turbo:load', function () {
  // Toggle element visibility
  var toggle = function (elem) {
    elem.classList.toggle('hide')
  }

  var div = document.getElementById('help_link')
  if (div !== null) {
    var panel = document.getElementById('help_panel')
    div.addEventListener('click', function (e) {
      toggle(panel)
    })
    var close = document.getElementsByClassName("close")[0];
    close.addEventListener('click', function (e) {
      toggle(panel)
    })
  }
})
