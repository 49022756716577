import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ['wrapper', 'field', 'operation', 'remove']
  

  get valueTargets() {
    return this.wrapperTarget.querySelectorAll('.value')
  }

  get valueTarget() {
    for (let valueTarget of this.valueTargets) {
      if (!valueTarget.disabled) {
        return valueTarget
      }
    }
  }

  get value() {
    return this.valueTarget.value
  }

  initialize () {
    // Remove this once jquery is gone
    const dates = this.wrapperTarget.querySelectorAll('.date')
    for (let date of dates) {
      $(date).fdatepicker({
        closeButton: false
      }).on('changeDate', ()=> {
        event = new Event('change')
        date.dispatchEvent(event)
      })
    }
  }

  changeField() {
    const optionName = this.fieldTarget.value
    const fields = this.wrapperTarget.querySelectorAll('.value')
    for (let field of fields) {
      const fieldName = field.getAttribute('data-field')
      if (optionName == fieldName) {
        field.style.display = ''
        field.removeAttribute('disabled')
      } else {
        field.style.display = 'none'
        field.setAttribute('disabled', 'true')
      }
    }
  }

  remove () {
    const container = this.wrapperTarget.parentNode
    container.removeChild(this.wrapperTarget)
  }

  submit() {
    if (this.value != '') {
      event = new Event('filter')
      document.getElementsByClassName('filters')[0].dispatchEvent(event)
    }
  }
}
