
import { Controller } from "@hotwired/stimulus"
const SVG_NS = "http://www.w3.org/2000/svg";
const SVG_HEIGHT = 2200;
const SVG_WIDTH = 5000;
const BOX_HEIGHT = 50;
const BOX_WIDTH = 160;

// Connects to data-controller="flow-to-svg"
export default class extends Controller {
  static targets = ['data']

  connect() {
    this.loadData()
  }

  async loadData() {
    try {
      // Fetch Data:
      let id = this.dataTarget.dataset.id
      fetch(`/interaction_flows/${id}/return_json`)
        .then((response) => response.json())
          .then((data) => this.constructSvg(data))
    } catch (error) {
      console.error('Fetch error:', error);
    }
  }

  constructSvg(data) {
    const flowchart = data.flowchart;
    const flowDataArray = JSON.parse(flowchart).drawflow.Home.data;
    const svg = this.createSvgElement('svg', { 'style': 'margin-bottom: 15px;', 'width': SVG_WIDTH, 'height': SVG_HEIGHT, 'id': 'svg_flowchart' });
    const { minYValue, minXValue } = this.getMinimumValues(flowDataArray)
    const connectionPositions = this.getConnectionPositions(flowDataArray, minYValue, minXValue);

    this.drawBorder(svg)
    this.drawConnections(svg, connectionPositions);
    this.drawNodes(svg, connectionPositions, flowDataArray, minYValue, minXValue);
    this.drawTexts(svg, flowDataArray, minYValue, minXValue);

    document.getElementById('flowchartsvg').appendChild(svg);
  }

  getMinimumValues(flowDataArray) {
    // Initialize min values
    let minYValue = 0;
    let minXValue = 0;

    const connectionPositions = [];

    for (const [key, value] of Object.entries(flowDataArray)) {
      const node = flowDataArray[key];

      if (node.outputs) {
        for (const [outputKey, outputValue] of Object.entries(node.outputs)) {

          outputValue.connections.forEach((connection, index) => {

            const endNodeKey = connection.node;

            if (flowDataArray[endNodeKey]) {
              const startX = node.pos_x;
              const startY = node.pos_y;
              const endX = flowDataArray[endNodeKey].pos_x;
              const endY = flowDataArray[endNodeKey].pos_y;

              // Update minYValue with the end node's pos_y
              if (startY < minYValue) {
                minYValue = startY;
              }

              // Update minYValue with the end node's pos_y
              if (endY < minYValue) {
                minYValue = endY;
              }

              // Update minYValue with the end node's pos_y
              if (startX < minXValue) {
                minXValue = startX;
              }

              // Update minYValue with the end node's pos_y
              if (endX < minXValue) {
                minXValue = endX;
              }

            }
          })
        }
      }
    }
    return { minYValue, minXValue }
  }

  getConnectionPositions(flowDataArray, minYValue, minXValue) {
    const connectionPositions = [];

    for (const [key, value] of Object.entries(flowDataArray)) {
      const node = flowDataArray[key];

      if (node.outputs) {
        for (const [outputKey, outputValue] of Object.entries(node.outputs)) {

          outputValue.connections.forEach((connection, index) => {
            const endNodeKey = connection.node;

            if (flowDataArray[endNodeKey]) {
              const startX = node.pos_x + 50;
              const startY = (node.pos_y + 50);
              const endX = flowDataArray[endNodeKey].pos_x + 50;
              const endY = (flowDataArray[endNodeKey].pos_y + 50);

              let offsetStartX = startX
              let offsetStartY = startY

              let offsetEndX = endX
              let offsetEndY = endY

              if (minYValue) {
                const yOffset = Math.abs(minYValue);
                // Ensure numbers are positive plus offset
                offsetStartY = yOffset + startY
                offsetEndY = yOffset + endY
              }

              if (minXValue) {
                const xOffset = Math.abs(minXValue);
                // Ensure numbers are positive plus offset
                offsetStartX = xOffset + startX
                offsetEndX = xOffset + endX
              }

              connectionPositions.push({
                start: { x: offsetStartX, y: (offsetStartY) },
                end: { x: offsetEndX, y: (offsetEndY) }
              });
            }
          });
        }
      }
    }
    return connectionPositions;
  }

  drawConnections(svg, connectionPositions) {
    connectionPositions.forEach((connection, index) => {
      const { start, end } = connection;

      let x1;
      x1 = start.x + BOX_WIDTH
      this.createCurvedLine(svg, x1, start.y, end.x, end.y, 1, '#4C1A7C');
    });
  }

  drawNodes(svg, connectionPositions) {
    // Draw all of the nodes
    let drawnNodes = new Set();
    connectionPositions.forEach((connection, index) => {
      const { start, end } = connection;

      // Only draw the first node once.
      if (!drawnNodes.has(start.x + ',' + start.y)) {
        this.createRectangle(svg, start.x, start.y);
        drawnNodes.add(start.x + ',' + start.y);
      }

      if (!drawnNodes.has(end.x + ',' + end.y)) {
        this.createRectangle(svg, end.x, end.y);
        drawnNodes.add(end.x + ',' + end.y);
      }
    });
  }

  drawTexts(svg, flowDataArray, minYValue, minXValue) {
    for (const [key, value] of Object.entries(flowDataArray)) {
      const node = flowDataArray[key];
      let endX = node.pos_x + 65;
      const endY = (node.pos_y + 50);
      let description = node.html.replace(/<\/?[^>]+(>|$)/g, '')
      let offsetEndX = endX
      let offsetEndY = endY      

      if (minYValue) {
        const yOffset = Math.abs(minYValue);
        // Ensure numbers are positive plus offset
        offsetEndY = yOffset + endY
      }

      if (minXValue) {
        const xOffset = Math.abs(minXValue);
        // Ensure numbers are positive plus offset
        offsetEndX = xOffset + endX
      }

      if (description) {
        description = description.replace(/^\s+/, "");
        description = description.replace(/^\t+/, "");
        description = description.length > 15 ? description.slice(0, 15) + "..." : description;
      }

      if (key === '1') {
        offsetEndX = node.pos_x + 65;

        if (minXValue) {
          const xOffset = Math.abs(minXValue);
          offsetEndX = offsetEndX + xOffset;
        }

      }

      this.createText(svg, offsetEndX, offsetEndY + 5, description);
    }

    // Create and append hover boxes and hover texts
    for (const [key, value] of Object.entries(flowDataArray)) {
      const node = flowDataArray[key];
      let endX = node.pos_x + 65;
      const endY = node.pos_y + 50;
      let offsetEndX = endX
      let offsetEndY = endY

      if (minYValue) {
        const yOffset = Math.abs(minYValue);
        // Ensure numbers are positive plus offset
        offsetEndY = yOffset + endY
      }

      if (minXValue) {
        const xOffset = Math.abs(minXValue);
        // Ensure numbers are positive plus offset
        offsetEndX = xOffset + endX
      }

      const description = node.html.replace(/<\/?[^>]+(>|$)/g, '');
      this.createHoverBox(svg, offsetEndX, offsetEndY + 5, description, description, node.data.type);
    }
  }

  createHoverBox(svg, x, y, text_content, description, action_type) {

    // Create hover box (initially hidden)
    var rect = document.createElementNS(SVG_NS, "rect");
    rect.setAttribute('x', x + (BOX_WIDTH * 1.1));
    rect.setAttribute('y', y - 80);
    rect.setAttribute('width', 350);
    rect.setAttribute('height', 350);
    rect.setAttribute('fill', '#fff');
    rect.setAttribute('stroke', '#E5E7EB'); // Border color
    rect.setAttribute('stroke-width', 2); // Border width
    rect.style.visibility = 'hidden';

    // Create foreignObject (initially hidden)
    var foreignObject = document.createElementNS(SVG_NS, "foreignObject");
    foreignObject.setAttribute('x', x + (BOX_WIDTH * 1.15));
    foreignObject.setAttribute('y', y - 60);
    foreignObject.setAttribute('width', 330);
    foreignObject.setAttribute('height', 330);
    foreignObject.style.visibility = 'hidden';

    // Create a div inside foreignObject
    var div = document.createElement('div');
    div.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
    div.style.fontSize = '14px';
    div.style.color = 'black';
    div.style.width = '320px'; // Adjust as needed
    div.style.height = 'auto';
    div.innerHTML = `
      <p><strong>Title:</strong> ${text_content}</p>
      <p><strong>Description:</strong> ${description}</p>
      <p><strong>Node Type:</strong> ${action_type}</p>
    `;

    foreignObject.appendChild(div);

    // Add mouseover event to the text
    const nodeText = svg.querySelector(`text[x="${x}"][y="${y}"]`);
    if (nodeText) {
      nodeText.addEventListener('mouseover', function () {
        rect.style.visibility = 'visible';
        foreignObject.style.visibility = 'visible';
        this.setAttribute('style', 'cursor:pointer;');
      });

      // Add mouseout event to revert style back to original
      nodeText.addEventListener('mouseout', function() {
        rect.style.visibility = 'hidden';
        foreignObject.style.visibility = 'hidden';
      });
    }

    svg.appendChild(rect);
    svg.appendChild(foreignObject);
  }

  // createStraightLine(svg, x1, y1, x2, y2, strokeWidth = 1, strokeColor = '#4C1A7C') {
  //   var line = document.createElementNS(SVG_NS, "line");
  //   line.setAttribute('x1', x1);
  //   line.setAttribute('y1', y1);
  //   line.setAttribute('x2', x2);
  //   line.setAttribute('y2', y2);
  //   line.setAttribute('stroke-width', strokeWidth);
  //   line.setAttribute('stroke', strokeColor);
  //   svg.appendChild(line);
  // }

  createCurvedLine(svg, x1, y1, x2, y2, strokeWidth = 1, strokeColor = '#4C1A7C') {
    var path = document.createElementNS(SVG_NS, "path");

   // Define the start point, control point, and end point
    let startPoint = { x: x1, y: y1 };
    let endPoint = { x: x2, y: y2 };

    let controlPoint1 = { x: (startPoint.x + endPoint.x) / 2, y: startPoint.y };
    let controlPoint2 = { x: (startPoint.x + endPoint.x) / 2, y: endPoint.y };

    // Set the 'd' attribute to draw a quadratic Bezier curve
    path.setAttribute('d', `M ${startPoint.x},${startPoint.y} C ${controlPoint1.x},${controlPoint1.y} ${controlPoint2.x},${controlPoint2.y} ${endPoint.x},${endPoint.y}`);

    // Set stroke and ensure the area under the curve is not filled
    path.setAttribute('stroke', strokeColor);
    path.setAttribute('fill', 'none');

    // Append the path to the SVG container
    svg.appendChild(path);
  }

  drawBorder(svg) {
    var rect = document.createElementNS(SVG_NS, "rect");
    rect.setAttribute('x', 0);
    rect.setAttribute('y', 0);
    rect.setAttribute('height', SVG_HEIGHT);
    rect.setAttribute('width', SVG_WIDTH);
    rect.setAttribute('style', 'fill:white;stroke:#E5E7EB;stroke-width:4;');
    svg.appendChild(rect);
  }

  createRectangle(svg, x, y) {
    var rect = document.createElementNS(SVG_NS, "rect");
    rect.setAttribute('x', x);
    rect.setAttribute('y', y - BOX_HEIGHT/2);
    rect.setAttribute('height', BOX_HEIGHT);
    rect.setAttribute('width', BOX_WIDTH);
    rect.setAttribute('style', 'fill:white;stroke:#E5E7EB;stroke-width:1;');
    rect.setAttribute('rx', 9);
    rect.setAttribute('ry', 9);
    svg.appendChild(rect);
  }

  createText(svg, x, y, text_content) {
    var text = document.createElementNS(SVG_NS, "text");
    text.setAttribute('x', x);
    text.setAttribute('y', y);
    text.setAttribute('width', BOX_WIDTH);
    text.setAttribute('height', BOX_HEIGHT);
    text.setAttribute('fill', 'black');
    text.textContent = text_content;

    svg.appendChild(text);
  }

  createTspan(hoverText, x, y, title, text) {
    var tspan = document.createElementNS(SVG_NS, "tspan");
    tspan.setAttribute("x", x);
    tspan.setAttribute("dy", y);
    tspan.textContent =  title + ': ' + text;
    hoverText.appendChild(tspan);
  }

  createDot(svg, x, y, radius = 2, color = '#4B197C') {
    var dot = document.createElementNS(SVG_NS, "circle");
    dot.setAttribute('cx', x);
    dot.setAttribute('cy', y);
    dot.setAttribute('r', radius);
    dot.setAttribute('fill', color);
    svg.appendChild(dot);
  }

  drawLine(svg, x1, y1, x2, y2) {
    const line = document.createSvgElement(SVG_NS, 'line')
    line.setAttribute('x1', x1);
    line.setAttribute('y1', y1);
    line.setAttribute('x2', x2);
    line.setAttribute('y2', y2);
    line.setAttribute('stroke', 'black');
    svg.appendChild(line);
  }

  createSvgElement(type, attributes) {
    const el = document.createElementNS(SVG_NS, type);
    for (const [key, value] of Object.entries(attributes)) {
      el.setAttribute(key, value);
    }
    return el;
  }

  downloadSvg() {
    var downloadLink = document.createElement("a");
    let id = this.dataTarget.dataset.id;

    downloadLink.href = "data:image/svg+xml;utf8," + encodeURIComponent(new XMLSerializer().serializeToString(this.dataTarget));
    downloadLink.download = `interaction-flow-svg-v${id} `+Math.round((new Date().getTime())/ 1000)+`.svg`;
    document.body.appendChild(downloadLink);

    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  sendSvgToServer() {
    var svg = document.getElementById('svg_flowchart');
    let id = this.dataTarget.dataset.id;

    var svgData = encodeURIComponent(new XMLSerializer().serializeToString(svg));

    fetch(`/interaction_flows/${id}/export_all_data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content // Rails CSRF token for security
      },
      body: JSON.stringify({ svg: svgData, type: 'all' })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Here we handle the blob response
      return response.blob();
    })
    .then(blob => {
      // Create a new URL for the blob object
      const url = window.URL.createObjectURL(new Blob([blob]));
      // Create a link to download it
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      // Use the Content-Disposition header to get the original file name
      // If your server doesn't provide this header, you can set a default name here
      link.setAttribute('download', `interaction-flow-v${id}-`+Math.round((new Date().getTime())/ 1000)+`.zip`);
      document.body.appendChild(link);
      link.click();
      // Clean up by revoking the Object URL and removing the link
      window.URL.revokeObjectURL(url);
      link.remove();
    })
    .catch(error => console.error('Error:', error));
  }

  scrollToTable() {
    document.getElementById("tables").scrollIntoView();
  }
}
