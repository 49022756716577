/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbo:load', function() {
    $('#sip_options_checkbox').click(function() {
      $('#sip_options').toggleClass('hidden');
       $('#sip_options input').val("");
    });
    $('.no_ivr_script_allowed').click(function() {
      $('input:checkbox').not(this).prop('checked', false);
      $('.remove_nested_fields_link').click();
      if ($(this).prop( "checked" )) {
         $('#ivr_wrapper').addClass('hidden');
      } else {
         $('#ivr_wrapper').removeClass('hidden');
      }
    });
     $('.multiple-outbound').multiSelect({
      afterSelect(values) {
        if ($('.multiple-outbound').val().length > 1) {
          $('.multiple-outbound').multiSelect('deselect',values);
           return alert('Only one number can be selected, please deselect the existing entry.');
        }
      }
    });
});
    
