import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["generateAudioCheckbox", "audioSelection"];

  toggleAudioSelection(event) {
    const checkbox = event.target;
    const audioSelection = this.audioSelectionTarget;

    if (checkbox.checked) {
      audioSelection.value = ''
      audioSelection.setAttribute("disabled", true);
      audioSelection.classList.add("disabled:bg-gray-200", "disabled:cursor-not-allowed");
    } else {
      audioSelection.removeAttribute("disabled");
      audioSelection.classList.remove("disabled:bg-gray-200", "disabled:cursor-not-allowed");
    }
  }

  uncheckGenerate(event){
    if(event.target.value != '') {
      this.generateAudioCheckboxTarget.checked = false 
      this.generateAudioCheckboxTarget.setAttribute("disabled", true);
      this.generateAudioCheckboxTarget.classList.add("bg-gray-200");
    } else {
      this.generateAudioCheckboxTarget.checked = true 
      this.generateAudioCheckboxTarget.setAttribute("disabled", false);
      this.generateAudioCheckboxTarget.classList.remove("bg-gray-200");
    }
  } 
}



