/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbo:load', function() {
  $('a#update_button').click(() => updateFormFields());

  return $('#user_password').keyup(function() {
      const password = $(this).val().trim();
      let strength = 0;


      if (password.length > 10) {
        strength += 1;
      }
      if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
        strength += 1;
      }
      if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
        strength += 1;
      }
      if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
        strength += 1;
      }
      if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/)) {
        strength += 1;
      }
      if (password.length < 10) {
        $('#result').removeClass();
        $('#result').addClass('short').html('Too short, add mroe characters');
        strength = 0;
      }
      if (strength < 3 ) {
        $('#result').removeClass('');
        $('#result').addClass('weak').html('Weak Password. Try adding special characters').addClass('text-red-500');
      } else {
        $('#result').removeClass();
        $('#result').addClass('strong').html('Strong Password').addClass('text-green-500');
      }
  });
});


var updateFormFields = function() {
    $('span#first_name_holder').text($('#user_first_name').val());
    $('span#surname_holder').text($('#user_surname').val());
    $('span#email_holder').text($('#user_email').val());
    if ($('#user_password_confirmation').val().length > 0) {
       $('span#password_confirm_holder').text('Password to be updated.');
    }
  };
