/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbo:load', function() {
    $('#tts_submit').click(e => $('div#loading').html('<div class="small-loader"></div>'));

    $('#ivr_form_container').on('change', '.audio_file', function(e) {
            const new_val = $(this).val();
            const edit_btn = $(this).parent().find('.edit-audio-btn');
            $(edit_btn).parent().attr('id', new_val);
             $(edit_btn).parent().attr('href', '/play_audio/' + new_val + '?' + new Date().getTime());
    });

    $('#ivr_form_container').on('click', '.fi-microphone', function(e) {
        $('body').find('.audio_file').removeClass('active');
         $(this).parent().parent().find('.audio_file').addClass('active');
    });

    $('#ivr_form_container').on('click', '#steps', function(e) {
        $('#draggable_info').addClass('hidden')
    });
});