/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbo:load', function() {
 
     $('body').on('click','.details-control[id]', () => 
      $('.verification_mark_passed').click(function(e) {
        if ($(this).hasClass( "disabled")) {
          return $(this).parent().parent().parent().find('.mark_as_passed.disabled:not(:hidden)').trigger('click');
        } else {
           $(this).parent().parent().parent().find('.mark_as_passed:not(:hidden):not(.disabled)').trigger('click');
        }
      }));
});
