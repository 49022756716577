
const ShowAudioShow = function () {

  $("div#intent-options").addClass('hidden');
  $("#ivr_step_stt_verify_text").val('');
  $("#ivr_step_stt_verify_step").val('');
  $('#ivr_step_nested_ivr_script_id').val('');
  $('a.remove_nested_fields_link').trigger("click");
   $("#Audio").removeClass('hidden');
}

const ShowCallFlowSelector = function () {
  $('#chained-flow').removeClass('hidden');
  let settings = {}
  select = document.getElementById('ivr_step_nested_ivr_script_id')

  if (!select.tomselect) {
    new TomSelect(select, settings);
  } else {
    select.tomselect.clear(true)
  }
}

const ShowTransferFields = function (el) {
  HideAudioShow(el);
  $('.value_holder').addClass('hidden');
  $("#xfre_dst_div").removeClass("hidden");
}

const ShowMultiAudioShow = function (el) {
  $("div#intent-options").addClass('hidden');
  $("#ivr_step_stt_verify_text").val('');
  $("#ivr_step_stt_verify_step").val('');
  $('#ivr_step_nested_ivr_script_id').val('');
  $('a.remove_nested_fields_link').trigger("click");
  $("#ivr_step_audio_file_id").val('');
  $("#Multi-Receive-Audio").removeClass('hidden');
  let chosenSelect = $('.chosen-select');
  if (chosenSelect.length > 0) {
    chosenSelect.chosen({
      allow_single_deselect: true,
      no_results_text: 'No results matched',
      width: "95%"
    });
    $('select.chosen-select option:empty').removeClass('hidden');
  }
};



const HideAudioShow = function (el) {
$("div#intent-options").addClass('hidden');
$("#ivr_step_stt_verify_text").val('');
$("#ivr_step_stt_verify_step").val('');
$('#ivr_step_nested_ivr_script_id').val('');
$("div#intent-options").addClass('hidden');
$("#DTMF-Pause").removeClass('hidden');     
$('a.remove_nested_fields_link').trigger("click");
$("#ivr_step_audio_file_id").val('');
$(".chosen-select").val('').trigger("chosen:updated");
if ($(el).val() === "DTMF") {
   $('.value_holder').removeClass('hidden');
   $(".is_variable_holder").removeClass('hidden');
} else {
  $('input.is_variable').trigger('click');
  $('.value_holder').removeClass('hidden');
  $('.variable_name_holder').addClass('hidden');
  $(".is_variable_holder").addClass('hidden');
}
};

const ShowSTTVerificationShow = function (el) {
$("div#intent-options").addClass('hidden');  
  $('a.remove_nested_fields_link').trigger("click");
  $("#ivr_step_audio_file_id").val('');
  $('#ivr_step_nested_ivr_script_id').val('');
  $(".chosen-select").val('').trigger("chosen:updated");
   $("#stt-verification").removeClass('hidden');
};

const ShowVariableShow = function () {
  
  $("div#intent-options").addClass('hidden');
  $('.value_holder').toggleClass('hidden');
  $('#ivr_step_value').val("");
  $('#ivr_step_variable_name').val("");
  $('#ivr_step_nested_ivr_script_id').val('');
  $('.variable_name_holder').toggleClass('hidden');
};

const ShowVariableResponseShow = function () {
$("#Variable-Response").removeClass('hidden');
};

const ShowResponseTimeoutShow = function () {
$("#await-response").removeClass('hidden');
};

const ShowAgentQuestionnaireShow = function () {
$("#agent-questionnaire").removeClass('hidden');
};


$(document).on('turbo:load', function() {
$('#new_audio_file').fileupload({
  dataType: "script",
  add(e, data) {
    const types = /(\.|\/)(ogg|mp3|wav|MP3|WAV|OGG)$/i;
    const file = data.files[0];
    if (types.test(file.type) || types.test(file.name)) {
      if (file.size < 5000001) {
        data.context_parent = $('<div class="w-full bg-gray-200 rounded-full dark:bg-gray-700"></div>')
        data.context = ($('<div class="bg-green-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style="width: 45%">Progress</div>'))
        data.context_parent.append(data.context)
        $('#new_audio_file').append(data.context_parent);
        return data.submit();
      } else {
        return alert(`${file.size} is too big. Max 5MB`);
      }
    } else {
      return alert(`${file.name} is not a mp3, wav or ogg audio file`);
    }
  },
  progress(e, data) {
    if (data.context) {
      const progress = parseInt((data.loaded / data.total) * 100, 10);
      return data.context.css('width', progress + '%');
    }
  },
  done: function(e, data) {
    data.context.html("Complete")
    data.context_parent.remove()
  }
});


$('body').on('change','select.step_type', function() {
  $(".options").addClass('hidden');
  if (($(this).val() === 'Send Audio') || ($(this).val() === 'Receive Audio') || ($(this).val() === 'Consult Transfer')) {
    return ShowAudioShow($(this));
  } else {
    if ($(this).val() === 'Receive Audio (multi)') {
      return ShowMultiAudioShow($(this));
    } else if ($(this).val() === 'Variable Response') {
      return ShowVariableResponseShow();
    } else if ($(this).val() === 'STT Verification') {
      return ShowSTTVerificationShow();
    } else if ($(this).val() === 'Chained Test Path') {
      return ShowCallFlowSelector();
    } else if ($(this).val() === 'Response Timeout') {
      return ShowResponseTimeoutShow();
    } else if ($(this).val() === 'Agent Questionnaire') {
      return ShowAgentQuestionnaireShow();
    } else if ($(this).val() === 'Send Utterance') {
      return $("div#intent-options").removeClass('hidden');
    } else if ($(this).val() === 'Chat Response') {
      return $("div#chat-response").removeClass('hidden');
    } else if ($(this).val() === 'Transfer: Blind Off-Net') {
      ShowTransferFields($(this));
    } else if ($(this).val() === 'Transfer: Blind On-Net') {

    } else if ($(this).val() === 'Transfer: Consult On-Net') {
      
    } else if ($(this).val() === 'Transfer: Complete') {

    } else {
      return HideAudioShow($(this));
    }
  }
  });

return $('body').on('click','#ivr_step_is_variable', () => ShowVariableShow());
});