
document.addEventListener('turbo:load', function(event) {
  const forms = document.querySelectorAll('form[method=get][data-turbo=true]')
  for (let form of forms) {
    form.addEventListener('submit', function (event) {
      event.preventDefault()
      const entries = [...new FormData(form).entries()]
      const url = new URL(form.action)
      entries.forEach(entry => url.searchParams.set(...entry))
      Turbo.visit(url.toString())
    })
  }
})

// document.addEventListener('turbo:before-render', function(event) {
//   Turbo.scroll = document.documentElement.scrollTop
// })

// document.addEventListener('turbo:render', function(event) {
//   if (Turbo.scroll < document.documentElement.scrollHeight) {
//     document.documentElement.scrollTop = Turbo.scroll
//   } else {
//     document.documentElement.scrollTop = document.documentElement.scrollHeight
//   }
// })
