drawbranches = function() {
  var treeData = JSON.parse($("#json-response").val());
  // Set the dimensions and margins of the diagram;
  var margin = { top: 20, right: 90, bottom: 0, left: 90 },
    width = 600 - margin.left - margin.right,
    height = 500 - margin.top - margin.bottom;

  // append the svg object to the body of the page
  // appends a 'group' element to 'svg'
  // moves the 'group' element to the top left margin
  var svg = d3
    .select("#branches-chart")
    .append("svg")
    .attr("width", width + margin.right + margin.left)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  var i = 0,
    duration = 750,
    root;

  // declares a tree layout and assigns the size
  var treemap = d3.tree().size([height, width]);

  // Assigns parent, children, height, depth
  root = d3.hierarchy(treeData, function(d) {
    return d.children;
  });
  root.x0 = height / 2;
  root.y0 = 0;

  // Collapse after the second level
  root.children.forEach(collapse);

  update(root);

  // Collapse the node and all it's children
  function collapse(d) {
    if (d.children) {
      d._children = d.children;
      d._children.forEach(collapse);
      d.children = null;
    }
  }

  function expand(d) {
    if (d._children) {
      d.children = d._children;
      d.children.forEach(expand);
      d._children = null;
    }
  }

  function update(source) {
    // Assigns the x and y position for the nodes
    var treeData = treemap(root);

    // Compute the new tree layout.
    var nodes = treeData.descendants(),
      links = treeData.descendants().slice(1);

    // Normalize for fixed-depth.
    nodes.forEach(function(d) {
      d.y = d.depth * 75;
    });

    // ****************** Nodes section ***************************

    // Update the nodes...
    var node = svg.selectAll("g.node").data(nodes, function(d) {
      return d.id || (d.id = ++i);
    });

    // Enter any new modes at the parent's previous position.
    var nodeEnter = node
      .enter()
      .append("g")
      .attr("class", "node")
      // .style('fill', '#fff')
      // .style('stroke', '#2099ac')
      // .style('stroke-width', '1.5px')
      .attr("transform", function(d) {
        return "translate(" + source.y0 + "," + source.x0 + ")";
      })
      .on("click", click);

    // Add Circle for the nodes
    nodeEnter
      .append("circle")
      .attr("class", "node")
      .attr("r", 1e-6)
      .style("stroke", "#2099ac")
      .style("stroke-width", "1px")
      .style("fill", function(d) {
        return d._children ? "#01579b" : "#fff";
      });

    // Add labels for the nodes
    nodeEnter
      .append("text")
      .style("font-size", "11px")
      .style("color", "#aaa")
      .attr("dy", ".35em")
      .attr("x", function(d) {
        return d.children || d._children ? -13 : 13;
      })
      .attr("text-anchor", function(d) {
        return d.children || d._children ? "end" : "start";
      })
      .attr("id", function(d) {
        return d.data.id;
      })
      .attr("desc", function(d) {
        return d.data.path_result;
      })
      // .text(function(d) { return d.data.name; });
      .text(function(d) {
        return d.data.id;
      });

    // UPDATE
    var nodeUpdate = nodeEnter.merge(node);

    // Transition to the proper position for the node
    nodeUpdate
      .transition()
      .duration(duration)
      .attr("transform", function(d) {
        return "translate(" + d.y + "," + d.x + ")";
      });

    // Update the node attributes and style
    nodeUpdate
      .select("circle.node")
      .attr("r", 5)
      .style("fill", function(d) {
        if (d.data.path_result == null || d.data.path_result.length != 0) {
          return d._children ? "#009b83" : "#fff";
        } else {
          return "#b70f27";
        }
      })
      .attr("cursor", "pointer");

    // Remove any exiting nodes
    var nodeExit = node
      .exit()
      .transition()
      .duration(duration)
      .attr("transform", function(d) {
        return "translate(" + source.y + "," + source.x + ")";
      })
      .remove();

    // On exit reduce the node circles size to 0
    nodeExit.select("circle").attr("r", 1e-6);

    // On exit reduce the opacity of text labels
    nodeExit.select("text").style("fill-opacity", 1e-6);

    // ****************** links section ***************************

    // Update the links...
    var link = svg.selectAll("path.link").data(links, function(d) {
      return d.id;
    });

    // Enter any new links at the parent's previous position.
    var linkEnter = link
      .enter()
      .insert("path", "g")
      .attr("class", "link")
      .style("fill", "none")
      .style("stroke", "#ccc")
      .style("stroke-width", "1.5px")
      .attr("d", function(d) {
        var o = { x: source.x0, y: source.y0 };
        return diagonal(o, o);
      });

    // UPDATE
    var linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate
      .transition()
      .duration(duration)
      .attr("d", function(d) {
        return diagonal(d, d.parent);
      });

    // Remove any exiting links
    var linkExit = link
      .exit()
      .transition()
      .duration(duration)
      .attr("d", function(d) {
        var o = { x: source.x, y: source.y };
        return diagonal(o, o);
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function(d) {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    // Creates a curved (diagonal) path from parent to the child nodes
    function diagonal(s, d) {
      path =
        "M " +
        s.y +
        " " +
        s.x +
        " C " +
        (s.y + d.y) / 2 +
        " " +
        s.x +
        "," +
        (s.y + d.y) / 2 +
        " " +
        d.x +
        "," +
        d.y +
        " " +
        d.x;

      return path;
    }

    // Toggle children on click.
    function click(d) {
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
      update(d);
      if (
        $(location)
          .attr("href")
          .search("mappers") > 0
      ) {
        //  $.get('/control_centre/ivr_mappers/call_transcript/' + d.data.id)
        $.get("/control_centre/ivr_mapper/child_details/" + d.data.id);
      } else {
        $.get("/control_centre/imported_ivr_steps/" + d.data.id + "/details");
      }
    }

    $("#expand_button").click(function() {
      source.children.forEach(expand);
      update(source);
    });

    $("#collapse_button").click(function() {
      root.children.forEach(collapse);
      update(root);
    });
  }
};

$(document).on("turbo:load", function() {
  $("body").on("change", "#imported_ivr_selector", function() {
    $("#branches-chart").html("");
    url = $("#imported_ivr_selector").val();
    $.ajax({
      url: url + "/heirachy.json?" + (new Date).getTime(),
      data: {
        format: "json"
      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
      success: function(data) {
        $("#json-response").val(JSON.stringify(data));
        drawbranches();
      },
      type: "GET"
    });
  });
  if ($("#branches-container").is(":visible")) {
    $.ajax({
      url: $(location).attr("href") + "/heirachy.json?" + (new Date).getTime(),
      data: {
        format: "json"
      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
      },
      success: function(data) {
        $("#json-response").val(JSON.stringify(data));
        drawbranches();
      },
      type: "GET"
    });

    $("body").on("click", "#download_svg_button", function() {
      table_data =
        "<text xmlns='http://www.w3.org/2000/svg' x='370' y='30' font-size='8px'>";
      node_texts = $("g.node text");
      for (i = 0; i < node_texts.length; i++) {
        table_data +=
          "<tspan x='370' dy='1em'>" +
          node_texts[i].id +
          ": " +
          node_texts[i].getAttribute("desc").replace(/%%stop%%/g, "") +
          "</tspan>";
      }
      table_data += "</text>";
      svgInner = $("svg")[0].innerHTML + "<g>" + table_data + "</g>";
      svgData =
        "<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><style>/* <![CDATA[ */ .node {cursor: pointer;}.node circle{fill: #fff;" +
        "stroke: #2099ac;stroke-width: 1.5px;}.node text {font-size: 11px;} path {fill: none;stroke: #ccc;stroke-width: 1.5px;}/* ]]> */</style>" +
        svgInner +
        "</svg>";
      var svgBlob = new Blob([svgData], {
        type: "image/svg+xml;charset=utf-8"
      });
      var svgUrl = URL.createObjectURL(svgBlob);
      var downloadLink = document.createElement("a");
      downloadLink.href = svgUrl;
      downloadLink.setAttribute("id", "downloadLink");
      downloadLink.download = "ets-discovery.svg";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }
});
