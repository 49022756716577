import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table-views"
export default class extends Controller {

  connect() {
  }

  resetTable(event) {
    event.preventDefault();
    const href = this.element.getAttribute("href"); 
    const turboFrame = this.element.dataset.turboFrame

    if (!href) {
      console.error("No href attribute found on the element");
      return;
    }

    // Confirm the action
    if (confirm("Are you sure you want to reset the table columns to their default settings?")) {
      fetch(href, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          if (response.ok) {         
            // Reset checkboxes            
            document.querySelectorAll("#table-view-form input[type='checkbox']").forEach(checkbox => checkbox.checked = false)            

            let url
            const param = event.target.dataset.filter
            if (event.target.value) {
              url = `${window.location.pathname}?${param}=${event.target.value}`
            } else {
              url = `${window.location.pathname}`
            }

            Turbo.cache.clear()
            Turbo.visit(url, { frame: turboFrame })


          } else {
            alert("Failed to delete");
          }
        });
    }
  }

  checkboxValiate(event) {    
    const tableViewForm = document.getElementById('table-view-form')

    if (tableViewForm) {
      const checkboxes = document.querySelectorAll('input[type="checkbox"][name^="table_view[table_view_columns_attributes"]');
      let oneChecked = false;

      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          oneChecked = true;
        }
      });

      if (!oneChecked) {
        event.preventDefault();
        alert("Please select at least one checkbox.");
      }      
    }
  }
}
