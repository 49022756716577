import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="test-case-validations"
export default class extends Controller {
  static targets = ['callFlowId', 'name', 'duration', 'stepContainer', 'addStepValidation', 'agentHolder']
  connect() {
    this.populate_details()     
  }  

  check_durations() {
    var min_duration = 0 
    const holders = Array.from(document.getElementsByClassName('ivr_holder'));
    const max_call_time = document.getElementById('max_call_time')
    const max_call_slide_min = document.getElementById('max_call_slide_min')
    const maxCallRange = document.getElementById('maxCallRange')
    const test_duration = document.getElementById('test_duration')

    holders.forEach(holder => {
      if (parseInt(holder.dataset.duration,10)  > min_duration) {
        min_duration = parseInt(holder.dataset.duration, 10)
      }

      if (max_call_time) {    
        max_call_time.min = min_duration  
        maxCallRange.min = min_duration  
        max_call_slide_min.innerHTML =  min_duration  

        if (parseInt(max_call_time.value,10) < min_duration) {
          max_call_time.value = min_duration
        }
        max_call_time.dispatchEvent(new Event('change'))
      }

      if (test_duration) {
        if (parseInt(test_duration.value) < parseInt(max_call_time.value)) {
          test_duration.value = max_call_time.value
        }
      }

    });
  }

  show_call_distribution() {
    const test_case_element = document.getElementById('test_case_test_type');
    const test_case_test_type = test_case_element ? test_case_element.value : null;
    if (test_case_test_type == "3") {
      document.querySelectorAll('.call_distribution').forEach(function(element) {
        element.classList.remove('hidden');
      }); 
    } else {
      document.querySelectorAll('.call_distribution').forEach(function(element) {
        element.classList.add('hidden');
      }); 
    }
  }


  async populate_details() {    
    const that = this
    const stepContainer = this.stepContainerTarget
    const ivr_id = this.callFlowIdTarget.value || $('.selected_ivr').val()
    this.callFlowIdTarget.value = ivr_id
    const response = await this.get_call_flow_details(ivr_id)
    this.nameTarget.innerHTML = response["script_name"]
    this.durationTarget.dataset.duration = response["duration"]

    if (stepContainer.querySelectorAll('.ivr_step_entry').length == 0) {
      for (const step of response["items"]) {
        this.addStepValidationTarget.click()
      }  
    }

    stepContainer.querySelectorAll('.ivr_step_entry').forEach(function(element, index) {
      let value_text; 

      const ivr_step_type = response.items[index]['ivr_step_type'];
      if (ivr_step_type === 'Send Utterance') {
          value_text = response.items[index]["intent_tags"]
      } else {
          value_text = response.items[index]["value"]          
        }
      

      if (response.items[index]["agent_action"] === true) {
        $('#latency-dial').removeClass('hidden');
        
        if (that.hasAgentHolderTarget) {
          that.agentHolderTarget.classList.remove('hidden');
        }
       
      }
      $(element).find('h3.step_description').html(response.items[index]['ivr_step_description'])
      $(element).find('span.step_type').html(ivr_step_type)
      $(element).find('span.step_value').html(value_text)
      $(element).find('span.step_audio_file').html(response.items[index]['audio_file_name'])
      $(element).find('.play_audio_link').attr('href', `/play_audio/${response.items[index]['audio_file_id']}`)
      $(element).find('.play_audio_link').attr('id', response.items[index]['audio_file_id'])
      $(element).find('input.ivr_step_id_hidden_field').val(response.items[index]["ivr_step_id"]); 
      $(element).find('input.audio_file_id_hidden_field').val(response.items[index]["audio_file_id"]);

      if (["DTMF", "Initial Response", "Send Audio"].indexOf(ivr_step_type) !== -1) {
        $(element).find("div.response_time_div").removeClass('hidden');
        $(element).find('.audio_holder').addClass('hidden')
      }

      if (["Send Audio", "Receive Audio", "Receive Audio (multi)"].indexOf(ivr_step_type) !== -1) {
        $(element).find('div.verify_div').removeClass('hidden');
        $(element).find('.play_audio_link').removeClass('hidden');
        $(element).find('.step_value_holder').addClass('hidden')
      }

      if (["Receive Audio"].indexOf(ivr_step_type) !== -1) {
        $(element).find('div.receive_audio_div').removeClass('hidden');
      }

      if (["Transfer: Blind Off-Net", "Transfer: Blind On-Net", "Transfer: Consult On-Net"].indexOf(ivr_step_type) !== -1) {
        $(element).find('div.xfre_dst_div').removeClass('hidden');
        if ($(element).find('.transfer_dst_input').val() === '') {
          $(element).find('.transfer_dst_input').val(response.items[index]['transfer_dst']);
        }
      }

      if (["Transfer: Blind On-Net", "Transfer: Consult On-Net"].indexOf(ivr_step_type) !== -1) {
        $(element).find('div.xfre_dst_div').removeClass('hidden');
        $(element).find('.transfer_dst_input').parent().addClass('hidden')
      }

      if (["Send Utterance"].indexOf(ivr_step_type) !== -1) {
        $(element).find('div.utterance_div').removeClass('hidden');
        const select = $(element).find('.utterance_select');
        $('option', select).remove();
        $.each(response.items[index]['utterance_options'], (val, text) => select.append("<option value=" + text[1] + ">" + text[0] + "</option>"));
        select.val($(element).find('.utterance_id_hidden').val());
        
        if ($(element).find('.intent_order').val() === '3') {
           $(element).find('.utterance_select_div').removeClass('hidden');
        } else { 
           $(element).find('.utterance_id_hidden').val('');
        }
      }

    })
    //$(ivr_holder).data("duration", response.duration);
    $('.ivr_ddi_select').multiSelect();
    $('.test_case_agent_group_ids').multiSelect();
    this.check_durations()
    this.show_call_distribution()
  }


  async get_call_flow_details(ivr_id) { 
    const res = await fetch('/test_cases/add_ivr_validation_steps/'+ ivr_id + ".json?" + (new Date).getTime())
    const result = await res.json()
    return result
  }


  toggle_steps(event) {
    event.preventDefault()
    this.stepContainerTarget.classList.toggle('hidden')

    // Toggle Text
    if (event.target.innerHTML === "Hide Steps") {
      event.target.innerHTML = "See Steps"
    } else {
      event.target.innerHTML = "Hide Steps"
    }
  }
  

}
