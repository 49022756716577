import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="side-nav"
export default class extends Controller {
  static targets = ['button', 'menu']
  
  connect() {
   
    }

  toggle() {
    this.menuTarget.classList.toggle('-ml-72')
    this.menuTarget.classList.toggle('ml-0')

    if (!document.getElementById('search_form').classList.contains('hidden')) {
      document.getElementById('search_form').classList.add('hidden')
    }
  }

  close() {
    if (this.menuTarget.classList.contains('ml-0')) {
      this.menuTarget.classList.add('-ml-72')
      this.menuTarget.classList.remove('ml-0')
    }
  }
}
