import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="icc-selector"
export default class extends Controller {
  static targets = ['country', 'breakouts', 'protocol', 'endpoint', 'sourceDdi', 'destination', 'endHolder', 'iccHolder']
  

  initialize () {
  }

  connect () {    
    if (this.element.dataset.country !== '') {
      this.countryTarget.value = this.element.dataset.country
    }
    if (this.hasCountryTarget && this.countryTarget.value !== '') {
      this.change()
    }
    if (this.hasProtocolTarget) {
      this.protocol_change()
    }
 
  }
  
  hide_icc_options () {
    if (this.hasBreakoutsTarget) {
      this.breakoutsTarget.value = ''
      this.breakoutsTarget.disabled = true
    }
    this.countryTarget.value = ''
    this.countryTarget.disabled = true
    this.iccHolderTargets.forEach(function(field) {
      field.classList.add('hidden')
    })
  }

  show_icc_options () {
    if (this.hasBreakoutsTarget) {
      this.breakoutsTarget.disabled = false
    }
    this.countryTarget.disabled = false
    this.sourceDdiTarget.value = ''
    this.sourceDdiTarget.disabled = true
    this.iccHolderTargets.forEach(function(field) {
      field.classList.remove('hidden')
    })
  }

  hide_endpoint_options() {
    this.endpointTarget.value = ''
    this.endpointTarget.disabled = true
    this.endHolderTarget.classList.add('hidden')
  }

  show_endpoint_options() {
    this.sourceDdiTarget.disabled = false
    this.endpointTarget.disabled = false
    this.endHolderTarget.classList.remove('hidden')
  }

  protocol_change () {
    if (!this.hasCountryTarget) {
      return
     }
    if (this.protocolTarget.value === "PSTN") {
      this.hide_endpoint_options()
      this.hide_icc_options()
      this.endHolderTarget.classList.remove('hidden')
      this.sourceDdiTarget.disabled = false
    } if (this.protocolTarget.value === "IN COUNTRY") {
      this.hide_endpoint_options()
      this.show_icc_options()
    } if (this.protocolTarget.value === "SIP") {
      this.hide_icc_options()
      this.show_endpoint_options()
    }
 
  }

  captureVal (event) {
    // Store previous company
    event.target.dataset.previousVal = event.target.value 
  }

  async change () {
   this.breakoutsTarget.innerHTML = 'Any';
   const countryval = this.countryTarget.value
   let breakouts = {}
   if (countryval !== '') {
    const res = await fetch(`/test_cases/get_breakouts/${countryval}`)
    breakouts = await res.json()
   }   

   if (breakouts.filter(o => o["state"] !== 0).length > 0) {
    this.updateDropdown(breakouts)
   } else {
      this.countryTarget.options[this.countryTarget.selectedIndex].remove();
      this.countryTarget.options[0].innerHTML = "COUNTRY OFFLINE - PLEASE SELECT ANOTHER"
   }

   if (this.hasDestinationTarget) {
    this.destinationChange()    
   }
  }

  splitCountryCode(value) {
    let str = value.split('+')
    str = str[str.length - 1]
    return str.replace(")", '')
  }

  destinationChange () {
    if (!this.hasCountryTarget || this.countryTarget.value == '') {
     return
    }
    const country_code =  this.splitCountryCode(this.countryTarget.options[this.countryTarget.selectedIndex].text)
    const after = this.after
    const previous_country = this.countryTarget.dataset.previousVal
    if (country_code !== '') {
      const destinations = this.destinationTarget.value
      let new_destinations = ''

      destinations.split(/\r?\n/).forEach(function(dst) {
        if (dst.slice(0, country_code.length) === country_code) {
          new_destinations = new_destinations + dst + '\r'
          return;
        }

        if (dst === '') {
          return;
        }
        let new_val = after(dst, previous_country)
        new_val = after(new_val, country_code)
        if (new_val[0] === '0') {
          new_val = new_val.slice(1, new_val.length)
        }
        new_destinations = new_destinations + country_code + new_val + '\r'
      });

      if (new_destinations === '' || new_destinations === previous_country) {
        new_destinations = country_code
      }
      this.destinationTarget.value = new_destinations
      this.destinationTarget.focus()
    }
  }

  newLineDestination(event) {
    if (!this.hasCountryTarget) {
      return
     }
     if (this.countryTarget.value == "") {
      return
     }
     const country_code = this.splitCountryCode(this.countryTarget.options[this.countryTarget.selectedIndex].text)
     if (country_code !== '') {
      this.destinationTarget.value = this.destinationTarget.value + country_code
     }
  }

  updateDropdown(data) {
      const dropdown = this.breakoutsTarget 
      const current_val =  this.element.dataset.breakout || dropdown.dataset.currentVal
      dropdown.innerHTML = "";
      data.forEach((breakout) => {
        const option = document.createElement("option");
        option.value = breakout.id == null ? '' : breakout.id;
        option.innerHTML = breakout.name;
        if (breakout.state == 0) {
          option.disabled = true;
        }
        dropdown.appendChild(option);
      });
      const blank = document.createElement("option");
      blank.value = '';
      blank.innerHTML = 'Any';
      dropdown.prepend(blank);
      dropdown.value = current_val
  }

  // This is for returning the string after a delimiter.

  after (value, delimiter) {  
    value = value || ''
  
    if (value === '') {
      return value
    }
  
    const substrings = value.split(delimiter)
  
    return substrings.length === 1
      ? value // delimiter is not part of the string
      : substrings.slice(1).join(delimiter)
  }
}
