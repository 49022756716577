import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multi-selectors"
export default class extends Controller {
  static targets = ['name']
  connect() {            
    if ($('#report_test_case_ids').is(':visible')) {
      this.UpdateTestCaseSelect();
    };
    if ($('#test_case_test_ddi_range_ids').is(':visible')) {
      this.UpdateTestDDISelect();
    };
    if ($('#test_case_ids').is(':visible')) {
      this.UpdateCampaignSelect();
    }
    if ($('#intent_test_ids').is(':visible')) {
      this.UpdateCampaignIntentSelect();
    }
    // $('#cc_index_content').on('click', '.add-filter-btn', function() {
    //   this.UpdateTestCaseSelect();
    // });    

    if ($('#test_multi').is(':visible')) {
      $('#test_multi').multiSelect();
    }

    if ($('#agent_ids').is(':visible')) {
      this.AgentMultiSelect();
    }

    if ($('#sas_ids').is(':visible')) {
      this.UpdateSAS();
    }

    if ($('#customer_ids').is(':visible')) {
      this.UpdateCustomer();
    }

    if ($('.multiSelectInit').is(':visible')) {
      this.multiSelectInit();
    }    
    
    if ($('.multiSelect').is(':visible')) {
      $('.multiSelect').multiSelect();
    }
    if ($('.ivr_ddi_select').is(':visible')) {
      this.UpdateTestDDISelect();
    }    

    if ($('.test_case_agent_group_ids').is(':visible')) {      
      this.UpdateAgentSelect()
      // $('.test_case_agent_group_ids').multiSelect();
    }
  }

  AgentMultiSelect () {
    $('#agent_ids').multiSelect({
      selectableOptgroup: true,
      selectableHeader: "<input type='text' size='15px' class='search-input' autocomplete='nochrome' placeholder='Filter by number'>",
      selectionHeader: "<input type='text' class='search-input' autocomplete='nochrome' placeholder='Filter by number'>",
      afterInit: function(ms){
  
          var that = this,
  
          $selectableSearch = that.$selectableUl.prev(),
          $selectionSearch = that.$selectionUl.prev(),
          selectableSearchString = '#'+that.$container.attr('id')+'  .ms-elem-selectable:not(.ms-selected)',
          selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';
          that.qs1 = $selectableSearch.quicksearch(selectableSearchString,{
                     'show': function () {
  
                          $(this).prev(".ms-optgroup-label").removeClass('hidden');
                          $(this).removeClass('hidden');
                      },
                      'hide': function () {
                          $(this).prev(".ms-optgroup-label").addClass('hidden');
                          $(this).addClass('hidden');
                      }
          })
          .on('keydown', function(e){
            if (e.which === 40){
              that.$selectableUl.focus();
              return false;
            }
          });
  
          that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
          .on('keydown', function(e){
            if (e.which == 40){
              that.$selectionUl.focus();
              return false;
            }
          });
      },afterSelect: function(){
        this.qs1.cache();
        this.qs2.cache();
      },
      afterDeselect: function(){
        this.qs1.cache();
        this.qs2.cache();
  
      }
      
    });
    $('.search-input').val('')

  }

  UpdateTestDDISelect () {
    $('.ivr_ddi_select').multiSelect({
      selectableOptgroup: true,
      selectableHeader: "<input type='text' size='15px' class='search-input' autocomplete='nochrome' placeholder='Filter by number'>",
      selectionHeader: "<input type='text' class='search-input' autocomplete='nochrome' placeholder='Filter by number'>",
      afterInit: function(ms){
  
          var that = this,
  
          $selectableSearch = that.$selectableUl.prev(),
          $selectionSearch = that.$selectionUl.prev(),
          selectableSearchString = '#'+that.$container.attr('id')+'  .ms-elem-selectable:not(.ms-selected)',
          selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';
          that.qs1 = $selectableSearch.quicksearch(selectableSearchString,{
                     'show': function () {
  
                          $(this).prev(".ms-optgroup-label").removeClass('hidden');
                          $(this).removeClass('hidden');
                      },
                      'hide': function () {
                          $(this).prev(".ms-optgroup-label").addClass('hidden');
                          $(this).addClass('hidden');
                      }
          })
          .on('keydown', function(e){
            if (e.which === 40){
              that.$selectableUl.focus();
              return false;
            }
          });
  
          that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
          .on('keydown', function(e){
            if (e.which == 40){
              that.$selectionUl.focus();
              return false;
            }
          });
      },afterSelect: function(){
        this.qs1.cache();
        this.qs2.cache();
      },
      afterDeselect: function(){
        this.qs1.cache();
        this.qs2.cache();
  
      }
      
    });
    $('.search-input').val('')

  }
  
  RefreshAgents () {    
    this.UpdateAgentSelect()
    // $('.test_case_agent_group_ids').multiSelect('refresh')
  }

  UpdateSAS () {
    $('#sas_ids').multiSelect({
      selectableOptgroup: true,
      selectableHeader: "<input type='text' name='gibber' size='15px' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      selectionHeader: "<input type='text' name='gibber2' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      afterInit: function(ms){
  
          var that = this,
  
          $selectableSearch = that.$selectableUl.prev(),
          $selectionSearch = that.$selectionUl.prev(),
          selectableSearchString = '#'+that.$container.attr('id')+'  .ms-elem-selectable:not(.ms-selected)',
          selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';
  
          that.qs1 = $selectableSearch.quicksearch(selectableSearchString, {
            'show': function() {
                $(this).prev(".ms-optgroup-label").removeClass('hidden');
                $(this).removeClass('hidden');
            },
            'hide': function() {
                $(this).prev(".ms-optgroup-label").addClass('hidden');
                $(this).addClass('hidden');
            }
        })
          .on('keydown', function(e){
            if (e.which === 40){
              that.$selectableUl.focus();
              return false;
            }
          });
  
          that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
          .on('keydown', function(e){
            if (e.which == 40){
              that.$selectionUl.focus();
              return false;
            }
          });

          // Bind select all behavior to an element          
          $('#add_all_sas_ids').off('click').on('click', function(e) {            
            e.preventDefault();                               
            // Get the values of all visible items
              var visibleValues = that.$selectableUl.find('.ms-elem-selectable:not(.ms-selected):not(.hidden)').map(function() {                
                return $(this).data('ms-value');                
            }).get();            

            // Select only the visible items
            that.$element.multiSelect('select', visibleValues);
          });

          // Bind Deselect all behavior to an element
          $('#remove_all_sas_ids').on('click', function(e) {
            e.preventDefault();
            that.$element.multiSelect('deselect_all');
          });
      },afterSelect: function(){
        this.qs1.cache();
        this.qs2.cache();               
      },
      afterDeselect: function(){
        this.qs1.cache();
        this.qs2.cache();
  
      }
    });
  }

  UpdateCustomer () {
    $('#customer_ids').multiSelect({
      selectableOptgroup: true,
      selectableHeader: "<input type='text' name='gibber' size='15px' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      selectionHeader: "<input type='text' name='gibber2' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      afterInit: function(ms){
  
          var that = this,
  
          $selectableSearch = that.$selectableUl.prev(),
          $selectionSearch = that.$selectionUl.prev(),
          selectableSearchString = '#'+that.$container.attr('id')+'  .ms-elem-selectable:not(.ms-selected)',
          selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';
  
          that.qs1 = $selectableSearch.quicksearch(selectableSearchString, {
            'show': function() {
                $(this).prev(".ms-optgroup-label").removeClass('hidden');
                $(this).removeClass('hidden');
            },
            'hide': function() {
                $(this).prev(".ms-optgroup-label").addClass('hidden');
                $(this).addClass('hidden');
            }
        })
          .on('keydown', function(e){
            if (e.which === 40){
              that.$selectableUl.focus();
              return false;
            }
          });
  
          that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
          .on('keydown', function(e){
            if (e.which == 40){
              that.$selectionUl.focus();
              return false;
            }
          });

          // Bind select all behavior to an element          
          $('#add_all_customer_ids').off('click').on('click', function(e) {            
            e.preventDefault();                               
            // Get the values of all visible items
              var visibleValues = that.$selectableUl.find('.ms-elem-selectable:not(.ms-selected):not(.hidden)').map(function() {                
                return $(this).data('ms-value');                
            }).get();            

            // Select only the visible items
            that.$element.multiSelect('select', visibleValues);
          });

          // Bind Deselect all behavior to an element
          $('#remove_all_customer_ids').on('click', function(e) {
            e.preventDefault();
            that.$element.multiSelect('deselect_all');
          });
      },afterSelect: function(){
        this.qs1.cache();
        this.qs2.cache();               
      },
      afterDeselect: function(){
        this.qs1.cache();
        this.qs2.cache();
  
      }
    });
  }
  
  UpdateTestCaseSelect () {
    
    $('#report_test_case_ids').multiSelect({
      selectableOptgroup: true,
      selectableHeader: "<input type='text' name='gibber' size='15px' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      selectionHeader: "<input type='text' name='gibber2' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      afterInit: function(ms){
  
          var that = this,
  
          $selectableSearch = that.$selectableUl.prev(),
          $selectionSearch = that.$selectionUl.prev(),
          selectableSearchString = '#'+that.$container.attr('id')+'  .ms-elem-selectable:not(.ms-selected)',
          selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';
  
          that.qs1 = $selectableSearch.quicksearch(selectableSearchString,{
                     'show': function () {
  
                          $(this).prev(".ms-optgroup-label").show();
                          $(this).show();
                      },
                      'hide': function () {
                          $(this).prev(".ms-optgroup-label").hide();
                          $(this).hide();
                      }
          })
          .on('keydown', function(e){
            if (e.which === 40){
              that.$selectableUl.focus();
              return false;
            }
          });
  
          that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
          .on('keydown', function(e){
            if (e.which == 40){
              that.$selectionUl.focus();
              return false;
            }
          });
      },afterSelect: function(){
        this.qs1.cache();
        this.qs2.cache();
      },
      afterDeselect: function(){
        this.qs1.cache();
        this.qs2.cache();
  
      }
    });
    $('#add_all_tests').click(() => $("#report_test_case_ids").multiSelect('select_all'));
    $('#remove_all_tests').click(() => $("#report_test_case_ids").multiSelect('deselect_all'));
  }

  //Campaigns
  UpdateCampaignSelect () {
    $('#test_case_ids').multiSelect({
      selectableOptgroup: true,
      selectableHeader: "<input type='text' size='15px' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      selectionHeader: "<input type='text' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      afterInit: function(ms){
  
          var that = this,
  
          $selectableSearch = that.$selectableUl.prev(),
          $selectionSearch = that.$selectionUl.prev(),
          selectableSearchString = '#'+that.$container.attr('id')+'  .ms-elem-selectable:not(.ms-selected)',
          selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';

          that.qs1 = $selectableSearch.quicksearch(selectableSearchString,{
                     'show': function () {
  
                          $(this).prev(".ms-optgroup-label").removeClass('hidden');
                          $(this).removeClass('hidden');
                      },
                      'hide': function () {
                          $(this).prev(".ms-optgroup-label").addClass('hidden');
                          $(this).addClass('hidden');
                      }
          })
          .on('keydown', function(e){
            if (e.which === 40){
              that.$selectableUl.focus();
              return false;
            }
          });
  
          that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
          .on('keydown', function(e){
            if (e.which == 40){
              that.$selectionUl.focus();
              return false;
            }
          });  

          // Bind select all behavior to an element
          $('#add_all_test_cases').off('click').on('click', function(e) {            
            e.preventDefault();                   
            // Get the values of all visible items
              var visibleValues = that.$selectableUl.find('.ms-elem-selectable:not(.ms-selected):not(.hidden)').map(function() {                
                return $(this).data('ms-value');                
            }).get();            

            // Select only the visible items
            that.$element.multiSelect('select', visibleValues);
          });

          // Bind Deselect all behavior to an element
          $('#remove_all_test_cases').on('click', function(e) {
            e.preventDefault();
            that.$element.multiSelect('deselect_all');
          });          

      },afterSelect: function(){
        this.qs1.cache();
        this.qs2.cache();
      },
      afterDeselect: function(){
        this.qs1.cache();
        this.qs2.cache();
  
      }
    });
    $('.search-input').val('')
  }

  UpdateCampaignIntentSelect () {
    $('#intent_test_ids').multiSelect({
      selectableOptgroup: true,
      selectableHeader: "<input type='text' size='15px' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      selectionHeader: "<input type='text' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      afterInit: function(ms){
  
          var that = this,
  
          $selectableSearch = that.$selectableUl.prev(),
          $selectionSearch = that.$selectionUl.prev(),
          selectableSearchString = '#'+that.$container.attr('id')+'  .ms-elem-selectable:not(.ms-selected)',
          selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';
          that.qs1 = $selectableSearch.quicksearch(selectableSearchString,{
                     'show': function () {
  
                          $(this).prev(".ms-optgroup-label").removeClass('hidden');
                          $(this).removeClass('hidden');
                      },
                      'hide': function () {
                          $(this).prev(".ms-optgroup-label").addClass('hidden');
                          $(this).addClass('hidden');
                      }
          })
          .on('keydown', function(e){
            if (e.which === 40){
              that.$selectableUl.focus();
              return false;
            }
          });
  
          that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
          .on('keydown', function(e){
            if (e.which == 40){
              that.$selectionUl.focus();
              return false;
            }
          });

          // Bind select all behavior to an element
          $('#add_all_intents').off('click').on('click', function(e) {            
            e.preventDefault();                   
            // Get the values of all visible items
              var visibleValues = that.$selectableUl.find('.ms-elem-selectable:not(.ms-selected):not(.hidden)').map(function() {                
                return $(this).data('ms-value');                
            }).get();            

            // Select only the visible items
            that.$element.multiSelect('select', visibleValues);
          });

          // Bind Deselect all behavior to an element
          $('#remove_all_intents').on('click', function(e) {
            e.preventDefault();
            that.$element.multiSelect('deselect_all');
          });

      },afterSelect: function(){
        this.qs1.cache();
        this.qs2.cache();
      },
      afterDeselect: function(){
        this.qs1.cache();
        this.qs2.cache();
  
      }
    });    
  }


  
  UpdateAgentSelect () {    
    $('.test_case_agent_group_ids').multiSelect({
      selectableOptgroup: true,
      selectableHeader: "<input type='text' name='gibber' size='15px' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      selectionHeader: "<input type='text' name='gibber2' class='search-input' autocomplete='nochrome' placeholder='Filter by name'>",
      afterInit: function(ms){
  
          var that = this,
  
          $selectableSearch = that.$selectableUl.prev(),
          $selectionSearch = that.$selectionUl.prev(),
          selectableSearchString = '#'+that.$container.attr('id')+'  .ms-elem-selectable:not(.ms-selected)',
          selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';
  
          that.qs1 = $selectableSearch.quicksearch(selectableSearchString,{
                     'show': function () {
  
                          $(this).prev(".ms-optgroup-label").show();
                          $(this).show();
                      },
                      'hide': function () {
                          $(this).prev(".ms-optgroup-label").hide();
                          $(this).hide();
                      }
          })
          .on('keydown', function(e){
            if (e.which === 40){
              that.$selectableUl.focus();
              return false;
            }
          });
  
          that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
          .on('keydown', function(e){
            if (e.which == 40){
              that.$selectionUl.focus();
              return false;
            }
          });
      },afterSelect: function(){
        this.qs1.cache();
        this.qs2.cache();
      },
      afterDeselect: function(){
        this.qs1.cache();
        this.qs2.cache();
  
      }
    });
  }
}

