$(document).on('turbo:load', function() {

  var elements = document.getElementsByClassName('click-to-copy');

  for (var i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', clickToCopy, false);
  }

  function clickToCopy() {
    // Get the text field
    var copyText = this.parentNode.querySelector('.copy-text')
    var result = this.parentNode.querySelector('.copy-result')

     // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.innerHTML);
  
    // Alert the copied text
    this.classList.toggle('hidden')
    result.classList.toggle('hidden'); 
    
    // Revert Copy message
    setTimeout(() => { result.classList.toggle('hidden'); this.classList.toggle('hidden');}, 1000, )
  }

  // Copy a field value to clipboard
  var vals2board = document.getElementsByClassName('value-to-clipboard');
  for (var i = 0; i < vals2board.length; i++) {
    vals2board[i].addEventListener('click', valueToClipboard, false);
  }

  function valueToClipboard() {
    // Get the text field
    var copyValue = document.getElementById(this.dataset.target)
    var result = this.parentNode.querySelector('.copy-result')
     // Copy the value of the text field
    navigator.clipboard.writeText(copyValue.value);
  
    // Alert the copied text
    this.classList.toggle('hidden')
    result.classList.toggle('hidden'); 
    
    // Revert Copy message
    setTimeout(() => { result.classList.toggle('hidden'); this.classList.toggle('hidden');}, 1000, )
  }
});
