import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="frame-refresher"
export default class extends Controller {
  static values = { interval: String }
  static targets = ["frame"]

  connect() {
      this.refreshTimer = setInterval(() => {
          this.refreshFrame();
      }, this.intervalValue || 10000); // Default to 10 seconds
  }

  disconnect() {
      clearInterval(this.refreshTimer);
  }

  refreshFrame() {
    this.frameTarget.reload()
  }
}
