import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rate-sheet"
export default class extends Controller {
  static targets = ['field', 'value', 'updateall', 'sheetId', 'table', 'tick', 'filterField', 'row']
  

  initialize () {
  }

  connect () {
  }

  filter() {
    let lowerCaseFilterTerm = this.filterFieldTarget.value.toLowerCase()

    this.rowTargets.forEach((el, i) => {
      let filterableKey =  el.getAttribute("data-filter-key").toLowerCase()
      el.classList.toggle("hidden", !filterableKey.includes( lowerCaseFilterTerm))
    })
  }

  reveal (e) {
    e.target.classList.add("hidden")
    e.currentTarget.parentNode.querySelectorAll('.row')[0].classList.remove("hidden")
  }

  update(e){
    let rate_sheet_id = this.sheetIdTarget.dataset.ratesheet
    let input = e.currentTarget.parentNode.querySelectorAll('input')[0]
    this.send_request(e, rate_sheet_id, null, 0, input.dataset.rate, input.value, true)
  }

  updateall(e) {
    let rate_sheet_id = this.sheetIdTarget.dataset.ratesheet
    let input = e.currentTarget.parentNode.querySelectorAll('input')[0]
    this.hideElement(this.tickTargets)
    document.getElementById('loading-header').innerHTML = "<div class='small-loader'></div>"
    this.send_request(e, rate_sheet_id, input.dataset.country, 'all', null, input.value, true) 
  }

  updatetype(e) {
    let rate_sheet_id = this.sheetIdTarget.dataset.ratesheet
    let input = e.currentTarget.parentNode.querySelectorAll('input')[0]
    this.hideElement(this.tickTargets)
    document.getElementById('loading-header').innerHTML = "<div class='small-loader'></div>"
    this.send_request(e, rate_sheet_id, null, input.dataset.protocol, null, input.value, true) 
  }

  close(e, price, multi) {
    if (multi) {
      location.reload()
    } else {
      e.target.parentNode.parentNode.parentNode.classList.add("hidden")
      let value = e.target.parentNode.parentNode.parentNode.parentNode.parentNode.querySelectorAll('span.value')[0]
      value.innerHTML = price
      value.classList.remove("hidden")
    }
 
  }

  error(e) {
    e.target.classList.add('error')
  }


 send_request(e, rate_sheet_id, country_id, protocol, rate_id, price, multi) {
  let error = this.error
  let close = this.close
  const params = {
    country_id: country_id,
    protocol: protocol, 
    rate_id: rate_id,
    price: price
  }

  const request = new XMLHttpRequest()
  request.open('POST', `/portal/rate_sheets/update_rate/${rate_sheet_id}.json`)
  request.setRequestHeader('Content-type', 'application/json')
  request.send(JSON.stringify(params)) 
  request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        close(e, price, multi)
      } else {
        error(e)
      }
  }

 }

 hideElement(element_array) {
  element_array.forEach( (elem) => { elem.classList.add("hidden")})
 }
}
