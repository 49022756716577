import { Controller } from "@hotwired/stimulus"
import { useTargetMutation } from 'stimulus-use'

import WaveSurfer from 'wavesurfer.js';
import RegionPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js';

var wavesurfer

// Connects to data-controller="audio-player"
export default class extends Controller {
  static targets = ['audioselector', 'container', 'playlink']
  

  connect() {
    this.hideContainers()
    useTargetMutation(this)
  }

  containerTargetAdded() {
    this.hideContainers()
  }

  containerTargetChanged() {
   this.hideContainers()
  }

  hideContainers() {
    if (this.hasPlaylinkTarget){
      this.playlinkTarget.click()
    } 
  }

  audio_change(event) {
    let play_link = event.target.parentNode.querySelectorAll(".play_audio_link")[0]
    play_link.id = event.target.value
    play_link.href = '/play_audio/' + event.target.value + '?' + new Date().getTime()
  }

  audio_close () {
    if ($('#audio-player-container').height() == 500) {
      $('#audio-advanced').trigger('click')
      $('#audio-advanced').data('audiotype', '')
      $('#audio-advanced').data('audioID', '')
    } 
    $('.fi-play').removeClass('secondary')
    $('#audio-player-container').addClass('hidden')
    $('.audio-control').removeClass('white')
    if (wavesurfer) {
      wavesurfer.destroy()
    }
  }

  advancetoggle () {

    if (this.containerTarget.offsetHeight > 150) {
      $('#audio-player-container').addClass('h-28')
      $('#audio-player-container').removeClass('h-128')
      $('#audio-advanced').text("ADVANCED")
    } else {
      $('#audio-player-container').removeClass('h-28')
      $('#audio-player-container').addClass('h-128')
      $('#audio-player-container').animate({height:500},500)
      $('#audio-advanced').text("SIMPLE")
      let audiotype = $('#audio-advanced').data('audiotype')
      let audioID = $('#audio-advanced').data('audioID')
      if (audiotype == 'audio') {
        $.get(`/control_centre/audio_files/${audioID}.js?ts=#{(new Date).getTime()}`)
      } else if (audiotype == 'call-audio') {
        $('#player-audio-form').html('<br><p class="text-white p-5 my-2">This file requires converting for use in Test Paths.</p><a href="/save_call/' + audioID + '" class="ml-5 btn btn-secondary" data-remote="true">Convert To Audio File</a>')
      } else if (audiotype == 'comparison') {
        $('#player-audio-form').html('<br><p class="text-white p-5 my-2">This file requires converting for use in Test Paths.</p><a href="/save_comparison/' + audioID + '" class="ml-5 btn btn-secondary" data-remote="true">Convert To Audio File</a>')
      } else if (audiotype == 'received_audio') {
        $('#player-audio-form').html('<br><p class="text-white p-5 my-2">This file requires converting for use in Test Paths.</p><a href="/save_received/' + audioID +  '" class="ml-5 btn btn-secondary" data-remote="true">Convert To Audio File</a>')
      }
    }
  }

  updateduration () {
    const start = $("#trim_start_time").val();
    const end = $("#trim_end_time").val();
    let duration = parseFloat(end) - parseFloat(start);
    if (duration < 0) {
        duration = 0;
      }
     $('input#duration').val(duration.toFixed(2));
     $('span#new_duration').text(duration.toFixed(2));
    };
    

  rewind() {
   wavesurfer.skipBackward()
  }

  play () {
    $('.audio-control').removeClass('text-white')
    $('#play').addClass('text-white')
    wavesurfer.play()
  }

  pause (event) {
    $('.audio-control').removeClass('text-white')
    $(event.target).addClass('text-white')
   wavesurfer.pause()
  }

  fastforward () {
   wavesurfer.skipForward()
  }

  mute (event) {
    $(event.target).toggleClass('fi-volume-strike').toggleClass('fi-volume')
   wavesurfer.toggleMute()
  }


  async play_audio_link(event) {

    event.preventDefault()
    if (this.hasPlaylinkTarget) {
      this.playlinkTarget.remove()
    }
    const UpdateDuration = this.updateduration
    let audio_link = event.currentTarget.href
    let audio_ID = event.currentTarget.id

    // Find out if using IE
    let IEversion = this.testBrowser()
    
    if (!IEversion) {
      if (wavesurfer !== undefined) {
       wavesurfer.destroy()
      }

     wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#eee',
        height: '75',
        progressColor: '#05A8AA',
        plugins: [
          CursorPlugin.create({
              showTime: true,
              opacity: 1,
              customShowTimeStyle: {
                  'background-color': '#000',
                  color: '#fff',
                  padding: '2px',
                  'font-size': '10px'
              }
          }),
          RegionPlugin.create({
            regions: [
  
            ],
            dragSelection: {
                slop: 5
            }
        })
        ]
    })

    // $('#audio-player-container').show()
    $('#audio-player-container').removeClass('hidden')

     await wavesurfer.load(audio_link)
     wavesurfer.on('interaction', function() {
       wavesurfer.clearRegions()
        $('#trim_start_time').val('')
        $('#trim_end_time').val('')
        $('input#duration').val(wavesurfer.getDuration().toFixed(2))
        $('span#new_duration').text(wavesurfer.getDuration().toFixed(2))
      })

     wavesurfer.on('region-created', function() {
       wavesurfer.clearRegions()
        $('#trim_start_time').val('')
        $('#trim_end_time').val('')
        $('input#duration').val(wavesurfer.getDuration().toFixed(2))
        $('span#new_duration').text(wavesurfer.getDuration().toFixed(2))
      })

     wavesurfer.on('region-update-end', function() {
        var region = Object.values(wavesurfer.regions.list)[0]
        $('#trim_start_time').val(region.start.toFixed(1))
        $('#trim_end_time').val(region.end.toFixed(1))
        UpdateDuration()
      })

     wavesurfer.on('ready', function() {
        // $('#audio_duration').html(wavesurfer.getDuration().toFixed(2))
        if (audio_link.indexOf('play_call_audio') != -1) {
          $('#audio-advanced').data('audiotype', 'call-audio' )
          $('#audio-advanced').data('audioID', audio_link.split("/").pop())
        } else if (audio_link.indexOf('comparison') != -1) {
          $('#audio-advanced').data('audiotype', 'comparison' )
          $('#audio-advanced').data('audioID', audio_link.split("/").pop())
        } else if (audio_link.indexOf('play_audio') != -1) {
          $('#audio-advanced').data('audiotype', 'audio' )
          $('#audio-advanced').data('audioID', audio_link.split("/").pop())
        } else if (audio_link.indexOf('play_received_audio') != -1) {
          $('#audio-advanced').data('audiotype', 'received_audio' )
          let arr = audio_link.split("/")
          let step = arr[arr["length"]-1]
          let id = arr[arr["length"]-2]
          $('#audio-advanced').data('audioID', id + "/" + step)
        }

        $('.audio-control').removeClass('text-white')
        $('#play').addClass('text-white')
        wavesurfer.play()
          
      })
        
    } else {
      // IE RESCUE PLAYER
      console.log("YOU ARE USING AN OLD IE!")
      $('#alt-audio-player-container').removeClass('hidden')
      $('#alt-audio-player-container').animate({height:100},500)
      $('.fi-play').removeClass('occam-orange')
      $(this).children().addClass('occam-orange')
      $('#alt-audio-player').attr("src",audio_link)
      $('#alt-edit-button').attr("href", '/control_centre/audio_files/' + audio_ID + '/edit' )
      let player = new MediaElementPlayer('#alt-audio-player',
        {alwaysShowControls: true,
        features: ['playpause','volume','progress','duration'],
        audioVolume: 'horizontal',
        audioWidth: 250,
        audioHieght: 20}
      )
      player.play()
      $('#alt-audio-close').click(function () {
        $('#alt-audio-player-container').fadeOut()
      })
      return false
    }
  }

  testBrowser() {
    let ua = window.navigator.userAgent
    let msie = ua.indexOf('MSIE ')
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
    }

    let trident = ua.indexOf('Trident/')
    if (trident > 0) {
      // IE 11 => return version number
      let rv = ua.indexOf('rv:')
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
    }
    return false
  }

}
