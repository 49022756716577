$(document).on("turbo:load", function() {  

  $.fn.recurring_select.texts = {
    locale_iso_code: "en",
    summary: "Summary",
    repeat: "Repeat",
    frequency: "Frequency",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
    every: "Every",
    days: "day(s)",
    weeks_on: "week(s) on",
    months: "month(s)",
    years: "year(s)",
    first_day_of_week: 1,
    day_of_month: "Day of month",
    day_of_week: "Day of week",
    cancel: "Cancel",
    ok: "OK",
    days_first_letter: ["S", "M", "T", "W", "T", "F", "S"],
    order: ["1st", "2nd", "3rd", "4th", "5th", "Last"]
  };

  // Show add Test Path to test button
  $('.selected_ivr').change(function() {    
    if ($(this).val() !== "") {      
        $("#add_ivr_link").addClass("btn btn-primary").removeClass('hidden');
    } else {        
        $("#add_ivr_link").addClass('hidden');
      }
  });

  // Disable the enter key press
  $("#test_case_form_holder form").bind("keypress",  function(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.returnValue = false;
        return false;
      }
  });

  // Show SIP options on end to end test
  $('#ivr_wrapper').on('click', '.sip_options_toggle', function(e) {
    const ele = $(this).parent().parent().parent().find('.sip_option_form');
    if ($(this).prop('checked')) {
        return ele.removeClass('hidden');
    } else {
        return ele.addClass('hidden');
      }
  });

    // Blank the max concurrent field if round robin is clicked on an IVR
    $('#ivr_wrapper').on('click','.round_robin_checkbox', function() {
      const call_dist_input = $(this).parent().find('.call_dist_input');
      console.log(call_dist_input)
      if ($(this).prop('checked')) {
          $(call_dist_input).val("0");
      } else {
          $(call_dist_input).val("");
        }
    });

    // Add and remove helper buttons for ddi selectors.
    $("#add_all_ddis").click(() => $("#test_case_test_ddi_range_ids").multiSelect('select_all'));
    $("#remove_all_ddis").click(() => $("#test_case_test_ddi_range_ids").multiSelect('deselect_all'));

})