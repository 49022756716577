import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ddis"
export default class extends Controller {
  
  static targets = ['template', 'links', 'tbody']

  connect () {
  }

  add_association (event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.tbodyTarget.insertAdjacentHTML('beforeend', content)
  }

  remove_association (event) {
    event.preventDefault()
    let agree = confirm("Are you sure you want to remove this?")
    if (agree == false) {
      return
    }
    let wrapper = event.target.parentNode.parentNode
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      event.target.nextElementSibling.value = 1;
      wrapper.style.display = 'none'
    }
  }
}
