import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-time-picker"
export default class extends Controller {
  connect() {
    // Set date to 30 days ago
    var date = new Date();
    date.setDate(date.getDate() - 30);
    $(".datepicker").flatpickr({
      dateFormat: "d-m-Y",
      defaultDate: date,
      disableDblClickSelection: true,
      pickTime: false,
      closeButton: true,
      autoclose: true,
      "locale": {
        "firstDayOfWeek": 1
      }
    })
    $(".datetimepicker").flatpickr({
      dateFormat: "d-m-Y H:i",
      defaultDate: new Date(),
      disableDblClickSelection: true,
      enableTime: true,
      closeButton: true,
      autoclose: true,
      "locale": {
        "firstDayOfWeek": 1
      }
    })
    $(".timepicker").flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
    })

    $(".tc-datepicker").flatpickr({
      dateFormat: 'd-m-Y',
      minDate:  new Date(new Date().setHours(0,0,0) - 86400000),
      disableDblClickSelection: true,
      pickTime: false,
      closeButton: true,
      autoclose: true,
      
      "locale": {
        "firstDayOfWeek": 1 // start week on Monday
      },
    });

    $(".tc-datetimepicker").flatpickr({            
      enableTime: true,
      dateFormat: 'd-m-Y H:i',
      disableDblClickSelection: true,      
      closeButton: true,
      autoclose: true,
      
      "locale": {
        "firstDayOfWeek": 1 // start week on Monday
      },
    });
  

    $(".datepicker").attr("autocomplete", "off");
    $(".datetimepicker").attr("autocomplete", "off");
    $(".timepicker").attr("autocomplete", "off");    
  }
}
