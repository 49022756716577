$(document).on("turbo:load", function() {  

// campaign test cases add and remove
$("#add_all_test_cases").click(() => $("#test_case_ids").multiSelect('select_all'));
$("#remove_all_test_cases").click(() => $("#test_case_ids").multiSelect('deselect_all'));

// intent tests add and remove
$("#add_all_intents").click(() => $("#intent_test_ids").multiSelect('select_all'));
$("#remove_all_intents").click(() => $("#intent_test_ids").multiSelect('deselect_all'));


})