import { Controller } from "@hotwired/stimulus"
import {GridStack} from "gridstack"
// Connects to data-controller="dashboard-component"
export default class extends Controller {
  async connect() {
    const update_panels = this.update_panels.bind(this)
    const get_panel_id = this.get_panel_id.bind(this)
    const fetch_data = await fetch('/dashboards/user_layout/1.json', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    })
    
    const user_data = await fetch_data.json()
    
    this.grid = GridStack.init({
      removable: '#trash', // drag-out delete class
      });
    this.grid.load(user_data.user_layout);

    this.grid.on('added', function(e, items) {
      update_panels(items)
    })

    this.grid.on('change', function(e, items) {
      update_panels(items)
    })

    this.grid.on('removed', async function(e, items) {
      const panel_id = await get_panel_id(e.detail[0].content).number
      fetch(`/dashboard_panels/${panel_id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token':$('meta[name="csrf-token"]').attr('content'),
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
      })
    })

    document.addEventListener('turbo:before-cache', () => {
      // Example: Clear content from a specific element before caching
      const elementToReset = document.getElementById('dashboard-grid');
      if (elementToReset) {
        elementToReset.innerHTML = ''
      }
    });

  }


  async get_panel_details(item) {
    const get_panel_id = this.get_panel_id.bind(this)
    var dataset = await get_panel_id(item.content)
    return {
      id: dataset.number,
      panel_config: {
        h: item.h,
        w: item.w,
        x: item.x,
        y: item.y}
    }
  }

  async update_panels(items) {
    var data = {}

    const get_panel_details = this.get_panel_details.bind(this)

    data["dashboard_panels"] = []

    for (const item of items) {
      const result = await get_panel_details(item);
      data["dashboard_panels"].push(result)
    }

    fetch(`/dashboard_panels/multi_update`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token':$('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify(data)
    })

    document.addEventListener("turbo:before-cache", this.destroy.bind(this));

  }



  get_panel_id(str) {
    const tempDiv = document.createElement('div'); // Create holder div
    tempDiv.innerHTML = str.trim(); // Convert the string to a DOM element
    const divElement = tempDiv.firstChild
    return divElement.dataset
  }

  save() {
    const gridData = this.grid.save(); // UNUSED
  }

  async add(e) {
    e.preventDefault()
    const fetch_data = await fetch(`/dashboard_panels`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token':$('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify({dashboard_panel: {name: 'new-panel', dashboard_id: 1}})
    })

    const user_data = await fetch_data.json()
    var node = {
      content: `<div data-controller='dashboard-panel-component' data-number='${user_data["id"]}'><div><span class='text-xs text-font-500 hover:underline cursor-pointer' data-action='click->dashboard-panel-component#edit'>Edit</span></div><turbo-frame id='panel_${user_data["id"]}'><p class='text-xs text-font-500'>New Panel</p></turbo-frame></div>`,
      autoPosition: true
    };
    
    let form_frame = document.querySelector('turbo-frame#edit_panel')
    if (form_frame) {
      form_frame.src = `/dashboard_panels/${user_data["id"]}/edit/`
    }

    this.grid.addWidget(node);
    return false;
  }

  destroy() {
    if (this.grid) {
      this.grid.destroy(false);
      delete this.element.dataset.initialized;
    }
  }

  form_validator(){

  }
  
  disconnect() {
    document.removeEventListener("turbo:before-cache", this.destroy.bind(this));
    this.destroy(); // Clean up when disconnected
  }

}
