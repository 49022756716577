import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['result', 'smsValue', 'btn', 'loader'];

  connect() {
  }

  testSms() {
    this.resultTarget.innerHTML = ''
    if (!this.hasSmsValueTarget) {
      console.error("SMS value is undefined");
      return;
    }

    this.btnTarget.classList.add('hidden')
    this.loaderTarget.classList.remove('hidden')

    const url = `/test_cases/test_sms/${this.smsValueTarget.value}.json?`;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network Error');
        }
        return response.json();
      })
      .then(data => {
        
        data.forEach(result => {
          this.resultTarget.innerHTML += `${result.number}: ${result.result} <br />`
        });
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        this.btnTarget.classList.remove('hidden')
        this.loaderTarget.classList.add('hidden')
      });
  }
}
