import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="post-validation"
export default class extends Controller {
  static targets = ['pvTemplate', 'integration', 'report', 'getFields', 'reportFields', 'getFieldsError', 'loader']
  

  initialize () {
  }

  connect () {
    if (this.integrationTarget.value !== '') {
      this.xhr_query()
    }
  }

  getHeaders (event) {
    event.preventDefault()
    if (this.integrationTarget.value !== '') {
      this.getFieldsErrorTarget.innerHTML = ''
      this.getFieldsTarget.parentNode.classList.add('hidden')
      this.loaderTarget.parentNode.classList.remove('hidden')
      this.xhr_query()
    } else {
      this.getFieldsErrorTarget.innerHTML = 'Please select Integration first.'
      setTimeout(() => this.getFieldsErrorTarget.innerHTML = '', 3000)
    }

  }

   xhr_query() {
    let populateDropdown = this.populateDropdown.bind(this)
    let id = this.integrationTarget.value
    let report_name = this.reportTarget.value
    let request = new XMLHttpRequest();
    let data = []
    request.open("GET", "/test_cases/get_post_call_fields/" + id + ".json?report_name=" + report_name, true);
    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        data = JSON.parse(request.responseText);
      }
      populateDropdown(data)

    };

    request.onerror = function() {
      populateDropdown(data)
    };

    request.send();
  }


  populateDropdown (data) {
    this.reportFieldsTargets.forEach((dropdown) => {
      let current_val = dropdown.value
      if (Object.entries(data).length === 0) {
        this.getFieldsErrorTarget.innerHTML = "Unable to get Report Headers - Is the report spelt correctly?"
      } else {
        this.getFieldsErrorTarget.innerHTML = ''
      }

      dropdown.innerHTML = ""
      data.forEach((field) => {
        const option = document.createElement("option");
        option.value = field;
        option.innerHTML = field;
        dropdown.appendChild(option);
      });
      dropdown.value = current_val
    })
    if (Object.entries(data).length !== 0) {
      this.pvTemplateTarget.content.querySelectorAll('select').forEach((template) => {
        template.innerHTML = ""
        data.forEach((field) => {
          const option = document.createElement("option");
          option.value = field;
          option.innerHTML = field;
          template.appendChild(option);
        });
      })
    }
    this.loaderTarget.parentNode.classList.add('hidden')
    this.getFieldsTarget.parentNode.classList.remove('hidden')
  }
}
