import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="nested-records"
export default class extends Controller {
  
  static targets =['links', 'template', 'override','useflow','ivr_script','ivr_script_id','name', 'verify']
  

  initialize () {
  }

  connect () {
    if (document.querySelectorAll('.test_select')) {
      document.querySelectorAll('.test_select').forEach((el)=>{        
        this.new_tom_select(el)        
      })          
    }

    // if (document.querySelector('#outbound_ivr_selector_holder')) {
    //   this.update_button()
    // }
  }

  update_button() {        
    var use_flow_holder = document.getElementById("use_flow_holder")
    var use_flow_dropdown = document.getElementById("ivr_script_dropdown")    

    if (this.is_ddi_visible()) {
      // Hide Button
      use_flow_holder.classList.add('hidden')
      // Hide form
      use_flow_dropdown.classList.add('hidden')
    } else {
      // Show Button
      use_flow_holder.classList.remove('hidden')
      // Show form
      use_flow_dropdown.classList.remove('hidden')
    }  
  }

  new_tom_select(el) {
    // Check if Tom Select has already been added
    if (!this.is_tom_select_added(el)) {
      let settings = {};
      // Initialise Tom Select
      new TomSelect(el,settings);
    }
  }

  is_tom_select_added(selectElement) {
    return (selectElement.classList.contains('tomselected') || selectElement.classList.contains('ts-wrapper')) 
  }

  toggle_button(event) {    
    // Show 'Use Flow' Button
    // this.useflowTarget.classList.remove('hidden')
    event.preventDefault()
    event.stopPropagation()        
    if (this.hasUseflowTarget) {      
      this.useflowTarget.classList.add('hidden')    
      if (this.is_ddi_visible() == false) {      
        // Show 'Use Flow' Button      
        this.useflowTarget.classList.toggle('hidden')
      }
    }
  }

  add_association (event) {    
    event.preventDefault()
    event.stopPropagation()
    this.add_fields(event)
  }

  is_ddi_visible() {
    var nested_fields = document.getElementsByClassName('nested-fields')    
    var visible = false

    for(let i = 0; i < nested_fields.length; i++) {            
      if ((nested_fields[i]).dataset.visible === 'true') {      
        visible = true
      }    
    }

    return visible
  }

  toggle_destination_numbers() {
    var hide_ddi = false

    if (this.is_ddi_visible()) {
      hide_ddi = true
    }   

    this.toggle_ddi(hide_ddi)
  }

  toggle_ddi(hide_ddi) {    
    var ddi_selector = document.getElementById('ddi_selector')
    var source_ddi = document.getElementById('source_ddi_holder')
    
    // var source_ddi = document.getElementById('test_case_source_ddi')
    if (hide_ddi === true) { 
      // Hide DDI Selector
      ddi_selector.classList.add('hidden')
      source_ddi.classList.add('hidden')
    } else {
      // Show DDI Selector
      ddi_selector.classList.remove('hidden')       
      source_ddi.classList.remove('hidden')     
    }
  }


  add_fields(event) {  
    const child_index = event.currentTarget.dataset.child || 'NEW_RECORD'
    const placement = event.currentTarget.dataset.placement || 'beforebegin'
    const re = new RegExp(child_index, 'g')
    var content = this.templateTarget.innerHTML.replace(re, `${new Date().getTime()}${Math.floor(Math.random() * 10000)}`)
    this.linksTarget.insertAdjacentHTML(placement, content)    

    if (document.querySelectorAll('.test_select') || document.querySelectorAll('.audio_file')) {
      document.querySelectorAll('.test_select').forEach((el)=>{        
        this.new_tom_select(el)        
      })         
      document.querySelectorAll('.audio_file').forEach((el)=>{        
        this.new_tom_select(el)        
      })        
    }

     this.toggle_button(event)
    // if (document.querySelector('#outbound_ivr_selector_holder')) {
    //   this.update_button()
    // }
  }

  remove_association (event) {    
    event.stopPropagation()
    event.preventDefault()    
    let agree = confirm("Are you sure you want to remove this?")
    let wrapper = this.findAncestor (event.currentTarget, 'nested-fields')

    if (agree) {
      if (wrapper.dataset.newRecord == "true") {
        wrapper.remove()
        // this.update_button()
        if (document.querySelector('#outbound_ivr_selector_holder')) {          
          this.toggle_destination_numbers()
        }
      } else {
        wrapper.querySelector("input[name*='_destroy']").value = 1
        wrapper.dataset.visible = 'false'
        wrapper.style.display = 'none'
            
        if (document.querySelector('#outbound_ivr_selector_holder')) {          
          this.toggle_destination_numbers()
        }
        // Set Dataset
        const data = event.target.parentElement.dataset
        var location = data.location

        // CHeck if test case
        // if (location == 'test-case') {
        //  this.toggle_destination_numbers()
        // }   
      }
      this.toggle_button(event)     
    }
  }

  remove_question (event) {
    event.preventDefault()
    let agree = confirm("Are you sure you want to remove this?")
    let wrapper = this.findAncestor (event.target, 'nested-fields')
    if (wrapper.dataset.newRecord == "true", agree) {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

  check_verify(event){
    if (event.target.value != '') {
      this.verifyTarget.checked = false
    } else {
      this.verifyTarget.checked = true
    }
  }

  // Find the correct parent element by Class
  findAncestor (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  }
}
