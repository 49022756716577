import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nameShow", "nameEdit", "nameValue", "editButton", "nameAlert", "nameId"];

  connect() {
  }

  toggleEdit(event) {
    event.preventDefault();

    this.nameShowTarget.classList.toggle('hidden');
    this.nameEditTarget.classList.toggle('hidden');
    this.editButtonTarget.classList.toggle('hidden');
    this.nameAlertTarget.classList.toggle('hidden');
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      console.log("Enter key pressed!");
      this.submitEdit(event)
    }
  }

  async submitEdit(event) {
    event.preventDefault();
    const id = this.nameIdTarget.dataset.id;
    const value = this.nameValueTarget.value;
    const url = this.nameIdTarget.dataset.url;


    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ name: value })
      });

      if (response.ok) {
        const json = await response.json();
        console.log('Successfully updated item:', id, 'New Value:', value);
        this.nameShowTarget.innerHTML = value;
        this.toggleEdit(event);
      } else {
        const json = await response.json();
        console.error('Failed to update item:', id, 'Error:', json.message);
        this.nameAlertTarget.innerHTML = `<div class='alert'>${json.message}</div>`;
        this.nameAlertTarget.classList.remove('hidden');
      }
    } catch (error) {
      console.error('Error during submit:', error);
      this.nameAlertTarget.innerHTML = `<div class='alert'>An error occurred. Please try again.</div>`;
      this.nameAlertTarget.classList.remove('hidden');
    }
  }
}
