import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ['btn', 'menu', 'reset']
  connect() {
  }

  toggle() {
    this.menuTarget.classList.toggle('hidden')
  }

  hide(event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('hidden')) {
      this.menuTarget.classList.add('hidden')
    }
  }

  reset_totals() {
    const form = this.resetTarget     
    form.submit()
  }

}
