import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard-form"
export default class extends Controller {
  static targets = ["chartSelect", "countrySelect", "protocolSelect"]

  connect() {
    this.validate()
  }

  validate() {
    this.reset_values()
    switch (this.chartSelectTarget.value) {
      case "pass_fail_by_country":
        this.countrySelectTarget.disabled = false
        this.protocolSelectTarget.value = ''
        break;
      case "pass_fail_by_protocol": 
        this.protocolSelectTarget.disabled = false
        this.countrySelectTarget.value = ''
        break;
    }
  }

  reset_values() {
    const items = [ this.countrySelectTarget,  this.protocolSelectTarget]

    // Looping over the array using forEach
    items.forEach((item) => {
      item.disabled = true
    });
  }
}
