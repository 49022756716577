import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['loader', 'multiContainer', 'selected','container']

  connect() {
    if (!this.hasContainerTarget) {
      console.log("MISSING CONTAINER")
      return 
    }


    $(this.containerTarget).multiSelect({
      selectableOptgroup: true,
      selectableHeader: `<input type='text' class='search-input' autocomplete='off' placeholder='Filter By Number'>`,
      selectionHeader: `<input type='text' class='search-input' autocomplete='off' placeholder='Filter By Number'>`,
      afterInit: function(ms){
        var that = this,
            $selectableSearch = that.$selectableUl.prev(),
            $selectionSearch = that.$selectionUl.prev(),
            selectableSearchString = '#'+that.$container.attr('id')+' .ms-elem-selectable:not(.ms-selected)',
            selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';
    
        that.qs1 = $selectableSearch.quicksearch(selectableSearchString)
        .on('keydown', function(e){
          if (e.which === 40){
            that.$selectableUl.focus();
            return false;
          }
        });
    
        that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
        .on('keydown', function(e){
          if (e.which == 40){
            that.$selectionUl.focus();
            return false;
          }
        });
      },
      afterSelect: function(){
        this.qs1.cache();
        this.qs2.cache();
      },
      afterDeselect: function(){
        this.qs1.cache();
        this.qs2.cache();
      }
    });
  }

  addAll(e) {
    $(this.containerTarget).multiSelect('select_all')
  }


  removeAll(e) {
    $(this.containerTarget).multiSelect('deselect_all')
  }

  async getRanges(event) {
    this.loaderTarget.classList.remove("hidden")
    const fetchData = await fetch(`/control_centre/test_ddi_ranges/${event.target.value}.json`)
    const data = await fetchData.json()  
    const selectElement = this.containerTarget
    const optgroup = document.createElement('optgroup')
    optgroup.setAttribute('label', data.name)
    data.ddis.forEach(ddi => {
      if (!Array.from(selectElement.options).map(option => option.value).includes(ddi.id.toString())) {
        const option = new Option(ddi.name, ddi.id)
        optgroup.appendChild(option)
      }
    });
    selectElement.appendChild(optgroup);
    $(selectElement).multiSelect('refresh')// Requires jQuery, consider removing dependency if possible.
    event.target.value = ''
    this.loaderTarget.classList.add("hidden")
  }
}
