$(document).on("turbo:load", function() {
  const tableView = document.getElementsByClassName('openTableView')


  if (tableView) {
    Array.from(tableView).forEach(element => {
      const revealTable = element.parentNode.querySelector ('.revealTable')
      const tableView = element.parentNode.querySelector ('.openTableView')
      element.addEventListener('click', () =>{
        revealTable.classList.remove('hidden')
        tableView.classList.toggle('hidden')
  
        revealTable.addEventListener('click', (e) =>{
        if (e.target.classList.contains('z-50')) {
        revealTable.classList.add('hidden')
        tableView.classList.remove('hidden') 
        }
      })
      })
    });
  }
 
})