import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="intent-helper"
export default class extends Controller {
  static targets = ['select', 'textField', 'library']

  clearSelect(){
    this.selectTarget.value = ''
  }

  clearText(){
    this.textFieldTarget.value = ''
  }

  clearAll(){
    this.textFieldTarget.value = ''
    this.selectTarget.value = ''
    console.log(this.libraryTarget.value)
    if (this.libraryTarget.value == 'true') {
      this.textFieldTarget.parentNode.classList.add('hidden')
    } else {
      this.textFieldTarget.parentNode.classList.remove('hidden')
    }
  }
}
