$(document).on("turbo:load", function() {
    // Test Slack message functionality button on monitoring
    $('#test-slack-btn').click(() => $.ajax('/test_cases/test_slack_message', {
      type: 'GET',
      dataType: 'json',
      contentType: "application/json",
      data: {
        token: $('#monitoring_profile_slack_authorization_token').val(),
        channel: $('#monitoring_profile_slack_channel').val()
      },
      error(jqXHR, textStatus, errorThrown) {
        $('#slack_notifier_div').html("Errors: " + jqXHR.responseJSON.message);
      },
      success(response) {
        $('#slack_notifier_div').html(response.message);
      }
    }
));

})