import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="session-checker"
export default class extends Controller {
  static targets = ['countdown']
  connect() {
    const sessionTimeout = 35 * 60 // seconds for 35 minutes - session length
    this.startTimer(sessionTimeout, this.countdownTarget)
  }

  startTimer(duration, display) {
    var duration_left = duration
    var minutes
    var seconds

    const interval = setInterval(function () {
        minutes = parseInt(duration_left / 60, 10);
        seconds = parseInt(duration_left % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds
        duration_left -= 1
        if (duration_left <= 0) {
          const link = document.createElement('a');
          link.href = "/users/logout";
          link.target = "_top";
        
          // Clear Turbo cache before redirecting
          if (Turbo) {
            Turbo.cache.clear();
          }
        
          // Append the link to the body and click it to trigger the logout
          document.body.appendChild(link);
          link.click();} 
        else if (duration_left < 300) {
          display.classList.add("text-alert-500")
        }
    }, 1000);
  }

}
