import Drawflow from 'drawflow'

class Drawflowoverride extends Drawflow {
  removeNodeId(id) {
   var deleteNode = confirm("Confirm Delete");
   if(deleteNode == true) {
    this.removeConnectionNodeId(id);
     var moduleName = this.getModuleFromNodeId(id.slice(5))
     if(this.module === moduleName) {
       document.getElementById(id).remove();
     }
     var data = this.drawflow.drawflow[moduleName].data[id.slice(5)].data
     delete this.drawflow.drawflow[moduleName].data[id.slice(5)];
     this.dispatch('nodeRemoved', [id.slice(5), data]);
   }
 }
}

$(document).on('turbo:load', async function () {

  const InteractionIndexPathRegex = /^\/interaction_flows$/;
  const InteractionPathRegex = /^\/interaction_flows\/\d+$/;
  const DiscoveryPathRegex = /^\/interaction_flows\/\d+\/discover\/\d+$/;
  const DiscoveriesPathRegex = /^\/interaction_flows\/\d+\/discoveries\/\d+$/;

  // Check if the current page matches path for Disabling Turbo
  if (InteractionIndexPathRegex.test(window.location.pathname) || InteractionPathRegex.test(window.location.pathname) || DiscoveryPathRegex.test(window.location.pathname) || DiscoveriesPathRegex.test(window.location.pathname)) {
  // Disable Turbo for this page
    document.body.setAttribute('data-turbo', 'false');
  }

  // SVG Icons
  const hide_svg = '<svg class="text-font-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" /></svg>'
  const show_svg = '<svg class="text-secondary-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" /><path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" /></svg>'
  const edit_svg = '<svg class="text-font-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" /></svg>'  

  // Set Default Canvas Position
  let canvas_x, canvas_y = 0;  

  if ($('#drawflow').is(':visible')) {
    var id = document.getElementById("drawflow");

    const editor = new Drawflowoverride(id);
    const data = await fetchData()
    // editor.reroute = true;
    
    editor.start();


    editor.reroute = true;
    editor.reroute_fix_curvature = true;


    // GetZoom 
    const zoom = editor.zoom;

    // Set Translate the function in after comment. 

    // Add zoom to function // Not tested
    editor.translate_to = function (x, y) {      
      this.canvas_x = x;
      this.canvas_y = y;
      this.precanvas.style.transform = "translate(" + this.canvas_x + "px, " + this.canvas_y + "px)";
    }

    function getTranslateXY(element) {
      const style = window.getComputedStyle(element)
      const matrix = new DOMMatrixReadOnly(style.transform)
      return {
          translateX: matrix.m41,
          translateY: matrix.m42
      }
    }

    async function saveFlow() {    
      // let pos = getTranslateXY(document.getElementsByClassName('drawflow')[0])      

      if (editor.editor_mode === 'edit') {
      // Modify export data to include new position
        const result = await fetch( `/interaction_flows/${id.dataset.flowId}` , {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          },          
          body: JSON.stringify({
            id: id.dataset.flowId,
            interaction_flow: {              
              pos_x: canvas_x, // Update starting canvas position
              pos_y: canvas_y,              
              flowchart: editor.export()
            }
          })          
        })
        if (result.status === 200) {
          var save_message = document.getElementById("save_message")
          save_message.outerHTML = "<span class='ml-8 text-green-500 fade-in-down 0.5s ease-out' id='save_message'>Saved</span>"
        }
      }
    }
  


    // MULT SHIFT DRAG .
    const nodesSelected = [];
    editor.on("clickEnd", (e) => {
        const shiftKey = e.shiftKey;
      if (shiftKey) {
        if (editor.node_selected !== null) {
                const nodeId = editor.node_selected.id.slice(5);
          const indexSelected = nodesSelected.indexOf(nodeId);
          if (indexSelected === -1) {
                    nodesSelected.push(nodeId);
          } else {
                  nodesSelected.splice(indexSelected, 1);
                  const nodeUnselected = document.getElementById(`node-${nodeId}`);
                  nodeUnselected.classList.remove("selected");
                }
        } else {
                nodesSelected.forEach(eleN => {
                    const node = document.getElementById(`node-${eleN}`);
                    node.classList.remove("selected");
                });
                nodesSelected.splice(0, nodesSelected.length);
            }
            nodesSelected.forEach(eleN => {
                    const node = document.getElementById(`node-${eleN}`);
                    node.classList.add("selected");
            });
      } else {
            nodesSelected.forEach(eleN => {
              const node = document.getElementById(`node-${eleN}`);
              if (node && node.classList) {
                node.classList.remove("selected");
              }
            });
            nodesSelected.splice(0, nodesSelected.length);
        }
        
    });

    let last_x = 0;
    let last_y = 0;
    let x_fixed = true;

    editor.on("mouseMove", ({ x, y }) => {
      if (editor.node_selected && editor.drag) {          
        editor.node_selected.classList.add("selected");
        const nodeId = editor.node_selected.id.slice(5);
        const indexSelected = nodesSelected.indexOf(nodeId);
        if (indexSelected === -1) {
          nodesSelected.push(nodeId);
        }
        nodesSelected.forEach(eleN => {
          if (eleN != editor.node_selected.id.slice(5)) {
            const node = document.getElementById(`node-${eleN}`);

            if (x_fixed) {
              // Locking X Axis
              const fixedX = node.offsetLeft;
              node.style.left = `${fixedX}px`;
              editor.drawflow.drawflow[editor.module].data[eleN].pos_x = (fixedX);              
            } else {
              var xnew = (last_x - x) * editor.precanvas.clientWidth / (editor.precanvas.clientWidth * editor.zoom);
              node.style.left = (node.offsetLeft - xnew) + "px";
              editor.drawflow.drawflow[editor.module].data[eleN].pos_x = (node.offsetLeft - xnew);
            }
            var ynew = (last_y - y) * editor.precanvas.clientHeight / (editor.precanvas.clientHeight * editor.zoom);
            node.style.top = (node.offsetTop - ynew) + "px";
            editor.drawflow.drawflow[editor.module].data[eleN].pos_y = (node.offsetTop - ynew);
            editor.updateConnectionNodes(`node-${eleN}`);
          }
        });
      }

      if (x_fixed) {
        last_x = 0;
      } else {
        last_x = x;
      }
      last_y = y;
    })  

    editor.on('import', function () {

    })

    // Import the current flow
    if (JSON.parse(data.flowchart)["drawflow"]) {
      // Update Flowchart to include button icons
      const flowchartData = loadNewIcons(JSON.parse(data.flowchart))

      // Retrieve Stored X,Y 
      canvas_x = Number(data.pos_x)
      canvas_y = Number(data.pos_y)
      editor.translate_to(canvas_x, canvas_y)      

      editor.import(flowchartData);      

      loadNodes()
      saveFlow()
    }

    function loadNodes() {
      // Find starting node
      const flow = editor.drawflow.drawflow.Home.data      

      // Find starting nodes (nodes with no incoming connections)
      const startingNodes = Object.keys(flow).filter(nodeId => {
        const inputs = flow[nodeId].inputs;
        return Object.keys(inputs).every(input => inputs[input].connections.length === 0);
      });

      // Assume the first node is the one with the smallest ID or the earliest in the list
      let firstNode = null;
      if (startingNodes.length > 0) {
        firstNode = startingNodes.reduce((first, nodeId) => {          
          return first === null || nodeId < first ? nodeId : first;
        }, null);
      }

      // Check if first nodes exists
      if (firstNode) {
        nodeId = firstNode
      } else {
        return
      }

      function loadChildNodes(parentNodeId) {

        const parentNode = editor.getNodeFromId(Number(parentNodeId))
        const childNodeIds = parentNode.outputs.output_1.connections.map(connection => connection.node)
        const allOutputNodeIds = parentNode.outputs.output_1.connections

        // Check for missing nodes
        if (allOutputNodeIds) {
          allOutputNodeIds.forEach(outputNodeId => {
            if (outputNodeId.node.length > 0) {
              try {
                // Try to locate output node
                outputNode = editor.getNodeFromId(outputNodeId.node);
              } catch (error) {
                // Node not found remove connection
                var data = editor.export()
                let connections = data["drawflow"]["Home"]["data"][parentNodeId].outputs.output_1.connections

                // Delete broken output id
                data["drawflow"]["Home"]["data"][parentNodeId].outputs.output_1.connections = connections.filter(connection => connection.node !== outputNodeId.node)

                editor.import(data)
                saveFlow()
              }
            }
          })
        }

        childNodeIds.forEach(childNodeId => {
          const childNodeElement = document.querySelector(`#node-${childNodeId}`)
          const connectionElements = document.querySelectorAll(`.node_in_node-${childNodeId}`)
          let node = null
          let display = null          

          if (childNodeId && childNodeElement) {
            node = editor.getNodeFromId(Number(childNodeId))
            display = getToggleStyle(node)
          } else {
            // Can't find Child Node Id            
            return
          }

          // Toggle child node display
          childNodeElement.style.display = display          

          connectionElements.forEach(element => {
            element.style.display = display;
          });

          // Only update hide icon if child nodes hidden
          if (display == 'none') {
            // Retrieve the input nodes of this child node
            const inputNodeIds = node.inputs.input_1.connections.map(connection => connection.node)

            inputNodeIds.forEach(inputNodeId => {
              const inputNodeElement = document.querySelector(`#node-${inputNodeId}`)
              const inputNode = editor.getNodeFromId(Number(inputNodeId))
              const hideButton = inputNodeElement.querySelector('.hide-btn')

              if (hideButton && !inputNode.hidden) {
                hideButton.innerHTML = display === 'none' ? show_svg : hide_svg
              }
            })
          }

          // Recursively toggle child nodes
          loadChildNodes(childNodeId);
        });
      }

      // Determine the new state for the child nodes based on the current state of the first child node
      const firstChildNode = editor.getNodeFromId(Number(nodeId)).outputs.output_1.connections[0]?.node;
      if (firstChildNode) {
        // Toggle all child nodes recursively
        loadChildNodes(nodeId)       
      }
    }

    function loadNewIcons(data) {
      const flowchartData = data
      const flow = flowchartData.drawflow.Home.data

      for (let key in flow) {
        if (flow.hasOwnProperty(key) && flow[key].hasOwnProperty('html')) {
          let flow_html = flow[key].html          

          if (flow_html.match(/<\/button><\/div>/)) {            
            break;
          } else {
            // Create a new DOMParser instance
            const parser = new DOMParser();

            // Parse the HTML string into a document
            const doc = parser.parseFromString(flow_html, 'text/html')

            // Extract the div with the class “target-div”
            const targetDiv = doc.querySelector('div.title-box')

            // Optionally, convert the extracted div back to a string
            const targetDivString = targetDiv.outerHTML            

            // Find the last occurrence of </div>
            let lastIndex = flow_html.lastIndexOf('</div>')

            // Replace the last </div> with the new content
            if (lastIndex !== -1) {
              flow_html = `<div>${targetDivString}<button class="hide-btn">${hide_svg}</button><button class="edit-btn">${edit_svg}</button></div>`
            }            

            flow[key].html = flow_html            
          }
        }
      }      
      return (flowchartData)
    }


    var selected_node_id = document.getElementById('details_pane')


    if (selected_node_id) {
      var node_att_id = selected_node_id.dataset.nodeId 
       if (node_att_id === "false" || node_att_id === undefined) {
         
       } else {
        document.getElementById(`node-${node_att_id}`).classList.add('selected')
        editor.selected_node = document.getElementById(`node-${node_att_id }`)
       }

    }

    if (document.getElementById('saveBtn').dataset.locked === 'true') {
      editor.editor_mode='fixed'
    } else {
      editor.editor_mode='edit'
    }

    // Allow for fixed layout boxes to be selected.
    editor.on("click", function (event) {
      if (document.getElementById('saveBtn').dataset.locked !== 'true' || event.target.className === 'title-box') {
        editor.editor_mode = 'edit';
      }
    });

    editor.on("clickEnd", async function(event) {
      if (document.getElementById('saveBtn').dataset.locked === 'true'){
        loadNodes()
        await saveFlow()
        editor.editor_mode = 'fixed';
      }
    });

    $('body').on('dblclick', '.drawflow-node', function (e) {      
      // Select the closest drawflow-node  
      let node_id = $(e.target).closest('.drawflow-node').attr('id')
      node_form(node_id)
    })  

    // Prevent double click action on hide and edit icons
    $('body').on('dblclick', '.hide-btn', function (e) {
      e.stopPropagation();
    })
    $('body').on('dblclick', '.edit-btn', function (e) {
      e.stopPropagation();
    })

    $('body').on('click', '.edit-btn', function (e) {
      e.stopPropagation();
      // Select the closest drawflow-node
      let node_id = $(e.target).closest('.drawflow-node').attr('id')
      node_form(node_id)      
    })

    $('body').on('click', '.hide-btn', function (e) {      
      e.stopPropagation();
      // Select the closest drawflow-node
      let node_id = $(e.target).closest('.drawflow-node').attr('id')
      toggleHideShow(node_id);
    })

    function center_canvas() {
      const flow = JSON.parse(data["flowchart"])["drawflow"]["Home"]["data"]
      const key = Object.keys(flow)[0]
      const first_node = flow[key]

      // Update global values
      canvas_x = (-1 * first_node.pos_x + 100)
      canvas_y = (-1 * first_node.pos_y + 100)

      if (first_node) {      
        editor.translate_to(canvas_x, canvas_y)
        saveFlow()
      }          
    }

    function node_form(node_id) {
      let node = editor.getNodeFromId(node_id.replace("node-", ""))
      if (!node.data.action_id && !node.data.prompt_id ) {
        alert ("Misconfigured Prompt - Please delete and re-create.");
        return
      }
      var url = ''
      switch(node.data.type) {
        case 'action': 
          url = `/interaction_flows/${id.dataset.flowId}/get_action/${node.data.action_id}`
          break;
        case 'prompt':
          url = `/interaction_flows/${id.dataset.flowId}/get_prompt/${node.data.prompt_id}`
          break;
      }
      let frame = document.querySelector('turbo-frame#details_pane_frame')
      frame.src = url
    //  document.location = url
      // Turbo.visit(url)
    }

    $('body').on('click','.discover_prompt', async function(e) {
      e.preventDefault()      
      const action = document.getElementById('details_pane')
      const parent_node = editor.getNodeFromId(action.dataset.nodeId)

      // add new prompt node 
      // const offset = getOffset(document.getElementById("node-" + action.dataset.nodeId))
      // const new_node = await addNodeToDrawFlow('prompt', offset.left + 250 ,  offset.top - 150)
      // editor.addConnection(parent_node.id, new_node, 'output_1', 'input_1')

      // Using initialise method to avoid node overlapping
      initialiseNode(e)
  })


    $('body').on('click','.add_branch', async function(e) {
      e.preventDefault();
      const prompt = document.getElementById('details_pane')
      const parent_node = editor.getNodeFromId(prompt.dataset.nodeId)

      // add new prompt node 
      const offset = getOffset(document.getElementById("node-" + prompt.dataset.nodeId))
      const new_node = await addNodeToDrawFlow('action', offset.left + 100, offset.top - 150)
      await editor.addConnection(parent_node.id, new_node, 'output_1', 'input_1')
      
      await fetch( `/interaction_flows/${id.dataset.flowId}/update_action_by_node/${new_node}` , {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify({interaction_action: {description: `Press ${e.target.dataset.option}`,dtmf: e.target.dataset.option, action_type: 1}})
      })
      var data = editor.export()
      data["drawflow"]["Home"]["data"][new_node].html = `<div><div class="title-box">Press ${e.target.dataset.option}</div><button class="hide-btn">${hide_svg}</button><button class="edit-btn">${edit_svg}</button></div>`
      editor.import(data)
      loadNodes()
      saveFlow()
    })

    
    $('body').on('click','#saveBtn', async function(e) {
      e.preventDefault()
      editor.editor_mode ='edit'
      await saveFlow()
      editor.editor_mode ='fixed'
      return false
    })


    $('body').on('click', '#submitBtn', async function (e) {
      var data = editor.export()
      let node_id = $('div.drawflow-node.selected').attr('id')

      if (node_id === undefined) {
        var form = document.getElementById('action_form') || document.getElementById('itemForm')
        node_id = form.querySelector('[data-node-id]').dataset.nodeId
      } else {
        node_id = node_id.slice(5)
      }

      data["drawflow"]["Home"]["data"][node_id].html = `<div><div class="title-box"> ${$('#descriptionField').val()} </div><button class="hide-btn">${hide_svg}</button><button class="edit-btn">${edit_svg}</button></div>`      

      editor.import(data)

      saveFlow()
      loadNodes()

      return true
    })


    $('body').on('keyup', '#descriptionField', function () {      
      var selected_node = $('div.drawflow-node.selected div div.title-box')
      if (selected_node.length === 0) {
        // Set form type
        var form = document.getElementById('action_form') || document.getElementById('itemForm')
        node_id = form.querySelector('[data-node-id]').dataset.nodeId

        $(`div#node-${node_id} div div.title-box`).text($('#descriptionField').val())
      } else {
        selected_node.text($('#descriptionField').val())
      }
    })


    async function fetchData() {
      const flowId = document.querySelector('#flowmain').dataset.flowId
      if (!flowId) {
        return
      }
      const fetchData = await fetch(`/interaction_flows/${flowId}.json`)
      const dataToImport = await fetchData.json()  
      return dataToImport
    }

    async function requestNode(node) {
      var url = '/interaction_flows/'
      switch(node.data.type) {
        case 'action': 
          url += 'actions.json'
          break;
        case 'prompt':
          url += 'prompts.json'
          break;
      }

      const result = await fetch(url , {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id.dataset.flowId, interaction_prompt: {description: 'Blank Prompt', node_id: node.id}, interaction_action: {description: 'Pause', pause: '5', action_type: 2, node_id: node.id}})
    })
    if (result.status == 200) {
      const data = await result.json()
      return data
    } else {
      return false
    }

    }


    editor.on('nodeCreated', async function (id) {
      saveFlow()
    })
  
    editor.on('nodeRemoved', function(node) {
      saveFlow()
      loadNodes()
      if (node[1].prompt_id === '') {
        return
      }
      switch(node[1].type) {
        case 'action': 
          url = `/interaction_flows/${id.dataset.flowId}/remove_action/${node[1].action_id}`
          break;
        case 'prompt':
          url = `/interaction_flows/${id.dataset.flowId}/remove_prompt/${node[1].prompt_id}`
          break;
      }
      fetch(url)
  
    })

    let fixedX = 0

    editor.on('nodeSelected', function (node_id) { 
      const nodes = document.querySelectorAll('.selected');      

      // Hide the node edit popup form
      document.querySelector('#details_pane').classList.add('hidden')

      nodes.forEach(node => {
        node.classList.remove('selected');
      });

      document.getElementById(`node-${node_id}`).classList.add('selected')
      node = editor.getNodeFromId(Number(node_id))

      // Set fixed pos_x
      fixedX = node.pos_x      
    })


    editor.on('moduleCreated', function(name) {

    })

    editor.on('moduleChanged', function(name) {

    })

    editor.on('connectionCreated', function(connection) {
      saveFlow()
      loadNodes()
    })

    editor.on('connectionRemoved', function(connection) {
      saveFlow()
      loadNodes()
    })

    editor.on('mouseMove', function(position) {
      // console.log('Position mouse x:' + position.x + ' y:' + position.y);      
    })

    editor.on('nodeMoved', function (id) {       

      var data = editor.export()      
      if (x_fixed === true) {

        // Preventing changes to the X Axis
        data['drawflow']['Home']['data'][id].pos_x = fixedX
        editor.import(data)
      } else {
        fixedX = data['drawflow']['Home']['data'][id].pos_x
        editor.import(data)
      }

      loadNodes()
      saveFlow()
    })

    editor.on('zoom', function(zoom) {
      // console.log('Zoom level ' + zoom);
    })

    editor.on('translate', function (position) {      
      // Update Canvas Position
      canvas_x = position.x
      canvas_y = position.y
    })

    editor.on('addReroute', function(id) {
      // console.log("Reroute added " + id);
    })

    editor.on('removeReroute', function(id) {
      // console.log("Reroute removed " + id);
    })



    /* DRAG EVENT */

    /* Mouse and Touch Actions */

    // var elements = document.getElementsByClassName('drag-drawflow');

    // for (var i = 0; i < elements.length; i++) {
    // elements[i].addEventListener('touchend', drop, false);
    // elements[i].addEventListener('touchmove', positionMobile, false);
    // elements[i].addEventListener('touchstart', drag, false );
    // }

    //   var mobile_item_selec = '';
    //   var mobile_last_move = null;

    //  function positionMobile(ev) {
    //    mobile_last_move = ev;
    //  }

   // Enable drop on canvas
    //  id.ondrop= function (event) {drop(event)}
    //  id.ondragover = function (event) {allowDrop(event)}

    //  function allowDrop(ev) {
    //     ev.preventDefault();
    //   }

    // function drag(ev) {
    //   if (ev.type === "touchstart") {
    //     mobile_item_selec = ev.target.closest(".drag-drawflow").getAttribute('data-node');
    //   } else {
    //     ev.dataTransfer.setData("node", ev.target.getAttribute('data-node'));
    //   }
    //   saveFlow()
    // }


    // function drop(ev) {

    //   if (ev.type === "touchend") {
    //     var parentdrawflow = document.elementFromPoint(mobile_last_move.touches[0].clientX, mobile_last_move.touches[0].clientY).closest("#drawflow");
    //     if (parentdrawflow != null) {
    //       addNodeToDrawFlow(mobile_item_selec, mobile_last_move.touches[0].clientX, mobile_last_move.touches[0].clientY);
    //     }
    //     mobile_item_selec = '';
    //   } else {
    //     ev.preventDefault();
    //     var data = ev.dataTransfer.getData("node");
    //     addNodeToDrawFlow(data, ev.clientX, ev.clientY);
    //   }
    // }

    // New Actions
    // var np = document.getElementById("newPrompt")
    // np && np.addEventListener("dragstart", function (event) {
    //   drag(event)
    // })


    // var na = document.getElementById("newAction")
    // na && na.addEventListener("dragstart", function (event) {
    //   drag(event)
    // })

    // --------------------

    const gridSpacing = 20 // Grid size
    const nodeSpacingX = 200 // Increased horizontal spacing
    const nodeSpacingY = 100 // Default vertical spacing
    let draggingEnabled = true
    let selectedNodeId = null

    // Lock nodes by default
    // editor.editor_mode = 'fixed'

    // Snap to grid function
    function snapToGrid(value) {
      return Math.round(value / gridSpacing) * gridSpacing;
    }

    $('body').on('click', '#newPrompt', async function (e) {
      initialiseNode(e)
    })

    $('body').on('click', '#newAction', async function (e) {
      initialiseNode(e)
    })

    function findSelectedElement(className) {
      // Get all elements with the specified class
      var element = document.querySelector('.' + className)

      return element
    }

    function initialiseNode(e) {

      let parentNodeId = null
      var nodes = document.querySelectorAll('.drawflow-node')
      // This is the type known as name
      var name = e.target.getAttribute("data-node")
      let newNodeX = null
      let newNodeY = null

      if (nodes.length >= 1) {
        // Find Selected Node
        element = findSelectedElement('selected')
        if (element) {
          nodeId = element.id          
        } else {
          alert('Please select a node before adding a new prompt or action node.')
          return
        }

        parentNodeId = extractNumberFromId(nodeId)
        const parentNode = editor.getNodeFromId(Number(parentNodeId))
        const childrenCount = parentNode.outputs.output_1.connections.length

        newNodeX = snapToGrid(parentNode.pos_x + nodeSpacingX)
        newNodeY = snapToGrid(parentNode.pos_y + (childrenCount * nodeSpacingY))        

        // Ensure the new node does not overlap existing nodes
        const nodes = Object.values(editor.drawflow.drawflow.Home.data)
        let overlap = true

        while (overlap) {
          overlap = nodes.some(node => node.pos_x === newNodeX && node.pos_y === newNodeY);
          if (overlap) newNodeY += nodeSpacingY; // Move the new node down to avoid overlap
        }        
      } else {        
        newNodeX = snapToGrid(100)
        newNodeY = snapToGrid(100)
      }      

      addNodeToDrawFlow(name, newNodeX, newNodeY, parentNodeId)
    }

    async function addNodeToDrawFlow(name, pos_x, pos_y, parentNodeId) {      

      var new_node
      switch (name) {
        case 'prompt':
          var prompt = `<div><div class="title-box">Blank<br>Prompt</div><button class="hide-btn">${hide_svg}</button><button class="edit-btn">${edit_svg}</button></div>`;
        var data = {
          type: 'prompt',
          prompt_id: ''
        }
        new_node = editor.addNode('prompt', 1,  1, pos_x, pos_y, 'prompt', data, prompt );
          break;

        case 'action':
          var action = `<div><div class="title-box">Blank<br>Action</div><button class="hide-btn">${hide_svg}</button><button class="edit-btn">${edit_svg}</button></div>`
          var data = {
            type: 'action',
            action_id: ''
          }
          new_node = editor.addNode('action', 1, 1, pos_x, pos_y, 'action',data, action );
          break;
      }

      // Connect new node to the selected node
      editor.addConnection(parentNodeId, new_node, 'output_1', 'input_1');      

      var node = editor.getNodeFromId(new_node)
      await requestNode(node).then(data => {
        if (data) {          
          updateNodeWithData(new_node, data);
        } else {          
          editor.removeConnectionNodeId(new_node);
          var moduleName = editor.getModuleFromNodeId(new_node)
          document.getElementById("node-" + new_node).remove();
          var data = this.drawflow.drawflow[moduleName].data[new_node].data
          delete this.drawflow.drawflow[moduleName].data[new_node];
          this.dispatch('nodeRemoved', [new_node, data]);
        }
      })
      node = editor.getNodeFromId(new_node)
      loadNodes()
      saveFlow()
      return new_node
    }

    function extractNumberFromId(id) {
      // Split the string by the hyphen and return the second part
      var parts = id.split('-');
      return parts[1];
    }

    function getToggleStyle(node) {
      if (!('hidden' in node)) {
        // Show node by default
        node.hidden = false
      }

      return node.hidden ? 'none' : 'flex'
    }   

    // Function to hide/show child nodes and connections
    function toggleHideShow(node_id) {
      // const nodeElement = document.querySelector(`#node-${nodeId}`);      
      let nodeId = Number(node_id.replace("node-", ""))
      let depth = 0

      function toggleChildNodes(parentNodeId, newState, display) {
        const parentNode = editor.getNodeFromId(parentNodeId)
        const childNodeIds = parentNode.outputs.output_1.connections.map(connection => connection.node)

        // Update Depth
        depth = depth + 1

        if (childNodeIds) {
          childNodeIds.forEach(childNodeId => {
            const childNodeElement = document.querySelector(`#node-${childNodeId}`)
            const connectionElements = document.querySelectorAll(`.node_in_node-${childNodeId}`)
            const childNode = editor.drawflow.drawflow.Home.data[childNodeId]

            inputNodeIds = childNode.inputs.input_1.connections.map(connection => connection.node);            

            let hiddenByParent = false

            inputNodeIds.forEach(inputNodeId => {
              const inputNodeElement = document.querySelector(`#node-${inputNodeId}`)
              const inputNode = editor.getNodeFromId(Number(inputNodeId))
              const hideButton = inputNodeElement.querySelector('.hide-btn')

      // Set hidden_by_parent to false by default
      if (!('hidden_by_parent' in childNode)) {
        childNode.hidden_by_parent = false
      }

      // Only update first level children
      if (depth === 1) {
        // Check if selected node is a input node
        if (newState) {
          hiddenByParent = inputNodeIds.includes(String(nodeId))
          childNode.hidden_by_parent = hiddenByParent
        } else {
          hiddenByParent = false
          childNode.hidden_by_parent = false
        }

                if (hideButton && !inputNode.hidden) {
                  hideButton.innerHTML = display === 'none' ? show_svg : hide_svg
                }
              } else {
                hiddenByParent = childNode.hidden_by_parent

        if (hiddenByParent) {
          if (hideButton && !inputNode.hidden) {
            hideButton.innerHTML = show_svg
          }
        }
      }
    })

            if (hiddenByParent && depth >= 2) {
              newState = true
              display = 'none'
            }          

            childNode.hidden = newState

            // Toggle child node connection
            childNodeElement.style.display = display;
            connectionElements.forEach(element => {
              element.style.display = display;
            });

            // Recursively toggle child nodes
            toggleChildNodes(childNodeId, newState, display);
          });
        }       
      }

      const firstChildNode = editor.getNodeFromId(nodeId).outputs.output_1.connections[0]?.node;

      if (firstChildNode) {      
        // Determine the new state and display style for the child nodes
        const node = getNodeState(firstChildNode, nodeId)
        const display = getToggleStyle(node)        

        // Toggle all child nodes recursively to the new state
        toggleChildNodes(nodeId, node.hidden, display)      
      }

      // Update the editor to redraw the connections correctly
      editor.updateConnectionNodes(nodeId)

      editor.export()
      saveFlow() 
    }

    function getNodeState(nodeId) {
      const node = editor.getNodeFromId(Number(nodeId))

      // Check if Hidden Exists
      if (!('hidden' in node)) {
        // Show node by default
        node.hidden = false
      }

      // Update Hidden Field
      if (node.hidden) {
        node.hidden = false;
      } else {
        node.hidden = true;
      }

      return node
    }


    // --------------------

    // Navigation Functions
    document.getElementById("df_zoomIn").onclick = function (e) {
      e.preventDefault()
      editor.zoom_in()
    }

    document.getElementById("df_zoomOut").onclick = function (e) {
      e.preventDefault()
      editor.zoom_out()
    }
    document.getElementById("df_zoomReset").onclick = function (e) {
      e.preventDefault()
      editor.zoom_reset()
      center_canvas()
    }

    document.getElementById("df_lockXAxis").onclick = function (e) {
      e.preventDefault()
      if (x_fixed) {
        x_fixed = false
        document.getElementById('unlock-icon').classList.remove('hidden')
        document.getElementById('lock-icon').classList.add('hidden')
      } else {
        x_fixed = true
        document.getElementById('unlock-icon').classList.add('hidden')
        document.getElementById('lock-icon').classList.remove('hidden')
      }
    }
    // document.getElementById("df_edit").onclick = function () {
    //   editor.editor_mode = 'edit'
    //   document.getElementById("df_edit").classList.add('hidden');
    //   document.getElementById("df_fixed").classList.remove('hidden');
    // }
    // document.getElementById("df_fixed").onclick = function () {
    //   editor.editor_mode = 'fixed'
    //   document.getElementById("df_fixed").classList.add('hidden');
    //   document.getElementById("df_edit").classList.remove('hidden');
    // }


    function getOffset( el ) {
      var _x = 0;
      var _y = 0;
      while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
          _x += el.offsetLeft - el.scrollLeft;
          _y += el.offsetTop - el.scrollTop;
          el = el.offsetParent;
      }
      return { top: _y, left: _x };
    }

    async function updateNodeWithData(new_node, data) {
      await editor.updateNodeDataFromId(new_node, data)
    }   

    var sv = document.getElementById("selectVersion")
    
    sv && sv.addEventListener("change", function (event) {
      window.location.href = '/control_centre/interaction_flows/' + event.target.value
    })

    var lock = document.getElementById("df_lock")
    
    lock && lock.addEventListener("click", function (event) {
      na.classList.toggle("hide")
      np.classList.toggle("hide")
      lock.classList.toggle("hide")
      if (editor.editor_mode ==='fixed') {
        editor.editor_mode='edit'
      } else {
        editor.editor_mode='fixed'
      }
    })

    
    

  }
})
