import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nlu-switcher"
export default class extends Controller {
  static targets=['select', 'dialogflow', 'dialogflowField', 'lex', 'lex_field']
  connect() {
    this.change()
  }

  change() {
    var selectedEngine = this.selectTarget.selectedOptions[0].text
    if (selectedEngine == 'dialogflow') {
      this.setToBlank(this.lexFieldTargets)
      this.lexTarget.classList.add('hidden')
      this.dialogflowTarget.classList.remove('hidden')
    } else if (selectedEngine == 'lex') {
      this.setToBlank(this.dialogflowFieldTargets)
      this.dialogflowTarget.classList.add('hidden')
      this.lexTarget.classList.remove('hidden')
    }
  }

  setToBlank(fields){
    if (!fields) {
      return
    }
    fields.forEach((field) => {
      field.value = ''
    })
  }
}
