/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbo:load', function() {
  $('#change-card a').click(function() {
    $('#change-card').hide();
    $('#credit-card').show();
    return $('#credit_card_number').focus();
  });
  return $('#cancel_card_change_button').click(function() {
    $('#change-card').show();
    $('#credit-card').hide();
    return $('#payment-form').trigger("reset");
  });
});
