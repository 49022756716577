import { Controller } from "@hotwired/stimulus"
let debounce = require("lodash/debounce");

// Connects to data-controller="sm-table"
export default class extends Controller {
  static targets = ['form','filter', 'header', 'order','checkboxToggle', 'submitBulkBtn', 'bulkForm', 'bulkMethod', 'expand','tomselect', 'anchor']
  

  initialize () {
  }

  connect () {   
    this.initialise_tomselect()
    // this.filter()
    let that = this
    that.filterTargets.forEach(element => {
      element.addEventListener('change', debounce(that.filter, 1000).bind(that))
      element.addEventListener('keyup', debounce(that.filter, 1000).bind(that))
    })

  }

  get_sort_column () {
    if (this.headerTargets.filter((t) => t.dataset.sorted === 'true')[0] === undefined) {
      // If no initial sort found set a default sortable header
      this.setInitalSortDefault(this.headerTargets[0])
    }

    return this.headerTargets.filter((t) => t.dataset.sorted === 'true')[0]
  }

  setInitalSortDefault(first_header) {
    // Assign default initial header
    first_header.dataset.sorted = true;
    first_header.classList.remove("hidden")
  }

  sort(e) {
    // Track the sort column
    // Just reorder if sorted already otherwise use default order
    if (e.currentTarget.dataset.sorted == "true") {
      e.currentTarget.dataset.direction = e.target.dataset.direction === "asc" ? "desc" : "asc"
    } else {
      this.headerTargets.map((t) => t.dataset.sorted = false)
      e.currentTarget.dataset.sorted = true
    }

    this.filter(e)
  
  }

  filter(e = null) {
    if (e) {
      e.preventDefault()
    }    
    const sort_column = this.get_sort_column()
    var orderName = this.hasOrderTarget ? this.orderTarget.dataset.ordername : 'order'
    var anchorPoint = this.hasAnchorTarget ? `#${this.anchorTarget.dataset.anchorname}` : ''
    const url = `${window.location.pathname}?${this.params}&${orderName}=${sort_column.dataset.order}&direction=${sort_column.dataset.direction}${anchorPoint}`  

    Turbo.cache.clear()
    Turbo.visit(url)
  }

  async clear(e) {
    e.preventDefault()
    const table_name = e.target.dataset.tablename  
    if (table_name) {
      // Remove any stored session filter data
      const res = await fetch("/users/clear_user_filter", {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
        },
        method: "POST",
        body: JSON.stringify({ table_name: table_name}),
      });
      console.log(res)
    }
    var anchorPoint = this.hasAnchorTarget ? `#${this.anchorTarget.dataset.anchorname}` : ''
    const url = `${window.location.pathname}${anchorPoint}`

    Turbo.cache.clear()
    Turbo.visit(url)
  }

  toggleCheckboxes(e) {
    e.preventDefault()
    const checkboxes = document.querySelectorAll('.bulk_checkbox')    
    for (let checkbox of checkboxes) {
      checkbox.checked = this.checkboxToggleTarget.text === 'Check All' ? true : false
    }
    this.checkboxToggleTarget.text  = this.checkboxToggleTarget.text === 'Check All' ? 'Uncheck All' : 'Check All'
    this.verifyBulkForm()
  }

  verifyBulkForm (e) {
    // Count total number of checkboxes for bulk_checkbox    
    var totalcheckboxesCount = this.bulkFormTarget.querySelectorAll('.bulk_checkbox').length
    // Count number of checkboxes checked
    var checkboxCheckedCount = this.bulkFormTarget.querySelectorAll('.bulk_checkbox:checked').length

    const noneChecked = this.bulkFormTarget.querySelectorAll('.bulk_checkbox:checked').length === 0       
    const noValue = this.bulkMethodTarget.value === ''
    const checkboxesButton = document.querySelector("[data-sm-table-target='checkboxToggle']")    

    // Check if All checkboxes selected
    if (totalcheckboxesCount == checkboxCheckedCount) {
      checkboxesButton.text = 'Uncheck All'
    }

    // Check if No checkboxes selected
    if (noneChecked) {
      checkboxesButton.text = 'Check All'
    }

    if (noneChecked || noValue) {
      this.submitBulkBtnTarget.classList.add('btn-disabled')
      this.submitBulkBtnTarget.disabled = true
    } else {
      this.submitBulkBtnTarget.classList.remove('btn-disabled')
      this.submitBulkBtnTarget.disabled = false
    }
  }  

  get params() {
    let final = []
    final.push(this.filterTargets.map((t) => !t.multiple && (t.checked || t.type != 'checkbox') ? `${t.name}=${t.value}` : null))

    let multiSelectDds = this.filterTargets.filter((t) => t.multiple)

    multiSelectDds.forEach(function (item) {
      final.push(Array.from(item.querySelectorAll("option:checked")).map((option) => `${item.name}=${option.value}`))
    });

    return final.flat().join("&")
  }

  initialise_tomselect() {
    if (this.tomselectTargets) {
      this.tomselectTargets.forEach((el)=>{
        if (!el.tomselect) {
          let settings = {};
          new TomSelect(el,settings);
        }
      });
    }
  }

}
