import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-refresh"
export default class extends Controller {
  connect() {
    console.log("Connected Auto Refresh")
    const timer = this.element.dataset.timervalue || 5000
    setTimeout(() => { document.location.reload(); }, timer);
  }
}
