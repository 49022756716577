import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="draggable"
export default class extends Controller {
  
  connect () {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    jQuery('.step.small-loader').removeClass("hidden")
    jQuery('.index').addClass("hide")
    let id = event.item.dataset.id
    let position 
    if (event.item.previousSibling) {
      position = parseInt(event.item.previousSibling.dataset.stepindex) + 1
    } else {
      position = 1
    }
    Turbo.visit(this.data.get("url").replace(":id", id).replace(":position", position))
  }
}
