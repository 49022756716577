let rootPath

$(document).on('turbo:load', function() {
  // Legacy

  $('#collapse_panel_btn').on("click", function () {
    if ($(this).data('collapse')) {
      // Collapsed so expand
      $('#panel2').addClass('large-4 large-push-8')
      $('#panel1').addClass('large-8 large-pull-4')
      $('.qt_form').show()
      $('#tc_form').show()
      $('#collapse_div').addClass('text-right columns')
      $('#collapse_panel_btn').removeClass('fi-arrow-right').addClass('text-right fi-arrow-left')
      $.get('/users/collapse_dashboard')
    } else {
      // Exapanded so collapse
      $('.qt_form').hide()
      $('#tc_form').hide()
      $('#panel1').removeClass('large-8 large-pull-4')
      $('#collapse_div').removeClass('columns')
      $('#collapse_panel_btn').removeClass('fi-arrow-left').addClass('fi-arrow-right')
      $('#panel2').removeClass('large-4 large-push-8')
      $.get('/users/collapse_dashboard')
    }
    $(this).data('collapse', !$(this).data('collapse'))
  })
})

function getRootPath() {
  if (rootPath == null) {
    rootPath = $('meta[name=root-path]').attr('content');
  }
  return rootPath;
}

function range(start, end, step, offset) {
  var len = (Math.abs(end - start) + (offset || 0) * 2) / (step || 1) + 1;
  var direction = start < end ? 1 : -1;
  var startingPoint = start - direction * (offset || 0);
  var stepSize = direction * (step || 1);

  return Array(len)
    .fill(0)
    .map(function(_, index) {
      return startingPoint + stepSize * index;
    });
}

