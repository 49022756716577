import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload-logo"
export default class extends Controller {
  
  static targets = ['selectLogoId', 'logoId']
  

  initialize() {
  }

  connect () {
  }

  useLogo (event){
    event.preventDefault()
    this.clearBorders()
    this.selectLogoIdTarget.parentNode.classList.add('logo_border')
    document.getElementById('company_logo_id').setAttribute('value', this.logoIdTarget.dataset.logoId)
  }

  clearBorders () {
    if (document.getElementsByClassName('logo_border').length > 0) {
      document.getElementsByClassName('logo_border')[0].classList.remove('logo_border')
    }
  }

  deleteLogo () {
    let hideDeleted = this.hideDeleted.bind(this)
    let request = new XMLHttpRequest();
    let data = []
    request.open("DELETE", "/portal/uploaded_image/" + this.logoIdTarget.dataset.logoId + ".json")
    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        data = JSON.parse(request.responseText);
      }  
      hideDeleted()
    };

    request.onerror = function() {
      console.log(data)
    };

    request.send()

  }

  hideDeleted() {
    this.selectLogoIdTarget.parentNode.classList.add('hide')
  }
}
