import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "startTime",
    "endTime",
    "convertedStartTime",
    "convertedEndTime",
    "timeZoneSelect",
    "timezone",
    "convertPrompt"
  ];

  connect() {
  }

  toggleTimeZoneSelect() {
    this.timeZoneSelectTarget.classList.toggle('hidden')
    this.convertPromptTarget.classList.toggle('hidden')
  }

  async updateTime() {
    const startTimeInput = this.startTimeTarget.value; 
    const endTimeInput = this.endTimeTarget.value;  
    const targetTimezone = this.timezoneTarget.value; 
    if (targetTimezone != '') {
      const fetch_data = await fetch(`/test_cases/local_time?start=${startTimeInput}&end=${endTimeInput}&timezone=${targetTimezone}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        }
      })
      const times = await fetch_data.json()
      this.convertedStartTimeTarget.innerHTML = times.start_time
      this.convertedEndTimeTarget.innerHTML = times.end_time  
    }
  }
}
