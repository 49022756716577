import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class ChartjsController extends Controller {
  static targets = ['chart']  
  
  connect() {            
    // Select chart
    var charts = this.chartTargets    
    var chart  = []
    
    for (chart of charts) {
      // Select all data elements    
      var ctx               = chart.getContext('2d')
      var chartType         = ctx.canvas.dataset.type
      var chartData         = JSON.parse(ctx.canvas.dataset.chartdata)
      var chartOptions      = JSON.parse(ctx.canvas.dataset.chartoptions)   
      var filterable         = ctx.canvas.dataset.filterable     


      // Create New Chart
      var rendered_chart = new Chart(ctx, {
        type: chartType,
        data: chartData,
        options: chartOptions
      })    

      if (filterable) {
        ctx.canvas.onclick = (evt) => {
          const res = rendered_chart.getElementsAtEventForMode(
            evt,
            'nearest',
            { intersect: true },
            true
          );
          // If didn't click on a bar, `res` will be an empty array
          if (res.length === 0) {
            return;
          }
          // Alerts "You clicked on A" if you click the "A" chart
          alert('You clicked on ' + rendered_chart.data.labels[res[0].index]);
        };
      }                
    }
  }

}