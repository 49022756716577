import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="interactions"
export default class extends Controller {
  static targets = ['form', 'interaction_id', 'interaction_flow_id', 'interaction_box', 'testForm', 'interaction_test_ids']
  connect() {
  }

  submit(event) {
    this.interaction_idTarget.value = event.target.dataset.interactionid
    Turbo.navigator.submitForm(this.formTarget)
  }

  select(event) {
    let target, final
    let arr = []

    if (event.target.tagName === 'SPAN') {
      target = event.target.closest('.label')
    } else {
      target = event.target
    }
    arr.push(this.interaction_test_idsTarget.value)

    if (target.classList.contains("alert")) {
      final =  arr.join(",").split(",").filter((v, i, a) => v !== target.dataset.interactionid)
    } else {
      arr.push(target.dataset.interactionid)
      final =  arr.join(",").split(",").filter((v, i, a) => a.indexOf(v) === i)
    }
   
    this.interaction_test_idsTarget.value = final
    target.classList.toggle("alert")
  }

  submitTestForm() {
    Turbo.navigator.submitForm(this.testFormTarget)
  }

  getRoot(event) {
    let frame = document.querySelector('turbo-frame#interaction')
    frame.src = `/control_centre/interaction_flows/${ this.interaction_flow_idTarget.value}/edit`
    const labels = Array.from(document.getElementsByClassName('select-border'));
    
    labels.forEach(box => {
      box.classList.remove('select-border');
    });
    event.target.closest('.label').classList.add('select-border')
    frame.scrollIntoView({behavior: 'smooth'})
  }

  getInteraction(event) {
    this.interactionFrameSrc(event)
    this.removeLabels()
    event.target.closest('.label').classList.add('select-border')
  }

  interactionFrameSrc(event) {
    let frame = document.querySelector('turbo-frame#interaction')
    frame.src = event.target.dataset.framesrc
    frame.scrollIntoView({behavior: 'smooth'})
  }

  close(event) {
    let frame = document.querySelector('turbo-frame#interaction')
    frame.src = `/control_centre/interaction_flows/${event.target.dataset.interactionflowid}`
    this.interactionFrameSrc(event)
    this.removeLabels()
  }

  removeLabels() {
    const labels = Array.from(document.getElementsByClassName('select-border'))
    
    labels.forEach(box => {
      box.classList.remove('select-border');
    });
  }
}
