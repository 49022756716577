
document.addEventListener('turbo:load', function(event) {
  $('#file-field-csv').on('change',  function() {
  const csv_field = $('#file-field-csv').val().length;

  if (csv_field === 0) {
    $('#import-csv-submit-button').prop('disabled', true);
  } else {
    $('#import-csv-submit-button').prop('disabled', false);
  }
});
})