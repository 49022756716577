import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trumbowyg-editor"
export default class extends Controller {
  static targets = ["trumbowyg"]
  connect() {   
    $.trumbowyg.svgPath = '/assets/icons/icons.svg'
    this.initializeEditor()
  }
  disconnect() {
    this.trumbowygTargets.forEach((target) => {
      $(target).trumbowyg('destroy')
    })
  }
  initializeEditor() {    
    this.trumbowygTargets.forEach((target) => {
      // do something with each target      
      $(target).trumbowyg();    
    })
  }
}