import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sliders"
export default class extends Controller {
  connect() {
    this.updateRampUpDisplay()
  }

  limitRange(event) {
    var slideValue = parseFloat(event.target.value)
    var slideValueMax = parseFloat(event.target.max)
    var slideValueMin = parseFloat(event.target.min)

    if (slideValue > slideValueMax) {
      // Set the target value to the max value          
      event.target.value = slideValueMax
    }
    if (slideValue < slideValueMin) {
      // Set the target value to the max value          
      event.target.value = slideValueMin
    }

    this.updateRampUpDisplay()

  }
  limitFloat(event) {
    // Set float value to max of 1 decimal points
    event.target.value = parseFloat(event.target.value).toFixed(1)
  }
  limitDuration(event) {
    var slide_value_min = parseFloat(event.target.value)
    var test_duration = document.getElementById('test_duration')
    var test_duration_slider = document.querySelector('#durationRange')
    var minimum_limit_test_duration = document.querySelector('#minimum_limit_test_duration')

    test_duration_slider.min = slide_value_min
    test_duration.min = slide_value_min
    minimum_limit_test_duration.innerHTML = slide_value_min

    if (test_duration.value < slide_value_min) {
      test_duration_slider.value = slide_value_min
      test_duration.value = slide_value_min
    }
  }


  updateRampUpDisplay() {
    const max_conncurent_field = document.getElementById('max_concurrent_calls')
    const ramp_up_span = document.getElementById('ramp_up_time_info')
    const max_calls_per_sec_field = document.getElementById('max_calls_per_sec')
    if (max_conncurent_field && ramp_up_span && max_calls_per_sec_field) {
      const max_calls_per_sec = max_calls_per_sec_field.value
      const max_concurrent_calls = max_conncurent_field.value
      var ramp_value = 0
      if (max_calls_per_sec != 0) {
        ramp_value = (max_concurrent_calls * (1 / max_calls_per_sec)).toFixed(0)
      }
      ramp_up_span.innerHTML = `Estimated Ramp Up Time: ${ramp_value} secs`
    }
  }
}