import jquery from "jquery"
import TomSelect from "tom-select"
var RecurringSelectDialog;
window.jQuery = jquery 
window.$ = jquery


/* Migration jQuery from 1.8 to 3.x */
window.jQuery.fn.load = function (callback) {
  var el = $(this);

  el.on('load', callback);

  return el;
};
window.$ = window.jQuery = jquery;
window.TomSelect = TomSelect
window.$ = window.jQuery = jquery;
window.TomSelect = TomSelect
