import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-pvfields"
export default class extends Controller {
  
  static targets = ['links', 'template', 'description']
  

  initialize () {
  }

  connect () {
    if (this.descriptionTargets.length == 0) {
      this.add_fields()
    }
  }

  add_association (event) {
    event.preventDefault()
    this.add_fields()
  }

  add_fields() {
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association (event) {
    event.preventDefault()
    let wrapper = event.target.parentNode.parentNode.parentNode.parentNode
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }
}
