import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slackhooks"
export default class extends Controller {
  static targets = ['code', 'channel', 'result']
  connect() {
 
  }

  async fireTest() {
    const data = {
      token: this.codeTarget.value, 
      channel: this.channelTarget.value
    }

    const res = await fetch( this.resultTarget.dataset.posturl, {
      method: 'POST',
      headers: {
        'X-CSRF-Token':$('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin', 
      body: JSON.stringify(data)
    })
    const final = await res.json()
    this.resultTarget.innerHTML = final.message
  }
}
