import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

// Use data-controller="panel-chart"
export default class extends Controller {
  static targets = ['chart' ]
  connect() {
    if (this.hasChartTarget) {
      this.load()
    }
  }

  async load() {
    var ctx = this.chartTarget.getContext('2d');
    var data = JSON.parse(this.chartTarget.dataset.chartdata)
    var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: data["timestamps"],
            datasets: [{
                label: 'Failed',
                data: data["failed"],
                backgroundColor: 'rgba(244, 71, 8, 1)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 0.3
            }, {
              label: 'Passed',
              data: data["passed"],
              backgroundColor: 'rgba(39, 110, 15, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 0.3
          }]
        },
            options: {
              scales: {
                x: {
                  stacked: true, // Enable stacking for x-axis
                },
                y: {
                  ticks: {
                    stepSize: 1,
                  },
                  beginAtZero: true,
                  stacked: true, // Enable stacking for y-axis
                  title: {
                    display: true,
                    text: 'Result'
                  }
                }
              },
                responsive: true, // Make chart responsive
                maintainAspectRatio: true,
                plugins: {
                  legend: {
                    labels: {
                      boxHeight: 5,
                      boxWidth: 5
                    }
                  }
                }
            }
        });
  }

}