import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="retry"
export default class extends Controller {
  static targets = ['link', 'count']
  

  readCount() {
    return parseInt(this.countTarget.textContent)
  }

  initialize() {
    this.intervalId = setInterval(() => {
      let count = this.readCount()
      if (count == 0) {
        clearTimeout(this.intervalId)
        this.linkTarget.textContent = 'Retry now'
      } else {
        count -= 1
        this.countTarget.textContent = count
      }
    }, 1000)
  }

  submit(event) {
    const count = this.readCount()
    if (count > 0) {
      event.preventDefault()
    }
  }

}
