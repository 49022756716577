import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="control-details"
export default class extends Controller {
  connect() {    
  }

  updateFailedCallStatus(failedCallId) {
      $.get('/control_centre/step_comparisons/mark-as-passed/'+failedCallId,{})    
  }

  markAsPassed(e) {
    e.preventDefault()
    const failedCallId = e.target.id
    this.updateFailedCallStatus(failedCallId)
  }

}