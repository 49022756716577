/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const SourceDdiRemove = function() {
  if ($("#test_case_source_ddi").is(":visible")) {
    $("#test_case_source_ddi").val('0');
    $("#test_case_source_ddi").attr("required", false);
     $("#source_ddi_holder").addClass('hidden');
  }
}

const SourceDdiAdd = function() {
  if (!$('.ivr_holder').is(":visible")) {
    $('.source_ddi_field').attr("required", false);
    $("#test_case_source_ddi").val('');
    $("#test_case_source_ddi").attr("required", 'required');
     $("#source_ddi_holder").removeClass('hidden');
  }
}

const Maximums = function () {
      const company_limit = $("#company_test_limit").data("value");
      const test_type = $('#test_case_test_type');
      const max_con = $("#test_case_max_concurrent_calls");
      const max_calls = $("#test_case_max_no_calls");
      const protocol = $('select.protocol_selector');
      if ((test_type.val() === "3") && ($(protocol).val() !== "IN COUNTRY")) {
        $(max_con).trigger(
          'configure',
          {
            max : company_limit
          }
        );
        $(max_con).data('max', company_limit);
        $(max_calls).data('max', company_limit * 10);
        $(max_calls).trigger(
          'configure',
          {
            max : company_limit * 10,
            min : 0
          }
        );
        $(max_calls).data('min', 0);

      } else if ($(protocol).val() !== "IN COUNTRY") {
        $(max_con).trigger(
            'configure',
            {
              max : 10
            }
          );
        $(max_con).data('max', 10);
        $(max_calls).trigger(
          'configure',
          {
            max : 10,
            min : 1
          }
        );
        $(max_calls).data('min', 1);
        $(max_calls).data('max', 10);
        if ($(max_con).val() > 10) {
            $(max_con).val(10).trigger('change');
          }
      } else {
          $(max_con).trigger(
            'configure',
            {
              max : 1
            }
          ).trigger('change');
          $(max_con).data('max', 1);
          $(max_con).val(1).trigger('change');
          $(max_calls).trigger(
            'configure',
            {
              max : 1,
              min : 1
            }
          );
          $(max_calls).data('min', 1);
        }
      $(max_con).trigger('change');
       $(max_calls).trigger('change');
    }



const ResultsPollerPoll = function () {
  setTimeout(ResultsPollerRequest, 5000);
};

const ResultsPollerRequest = function () {
  $.get($('#cancel_test').data('url') + '?' + (new Date).getTime());
}


const PreCheckPollerPoll = function () {
  // setTimeout(this.request, 10000);
}

const PreCheckPollerRequest = function () {
  //  $.get($('#pre_check_div').data('url'));
}

const UpdateFrequencyUpdate = function () {
        if ($('#test_case_test_type').val() === '2') {
          let number_selected_ddis = 0;
          $('.ivr_ddi_select').each((index, element) => {
              if (($(element).val() !== null) && $(element).removeClass("hidden")) {
                   number_selected_ddis = number_selected_ddis + $(element).val().length;
                }
          });
          const test_recurrence = parseInt($('#test_case_repeat_frequency').val(),10);
          const test_duration = parseInt($('#test_case_test_duration').val(),10);
          const test_concurrent = parseInt($('#test_case_max_concurrent_calls').val(),10);
          if (((test_duration * number_selected_ddis) / test_concurrent) > test_recurrence) {
            alert('Please increase the call frequency or reduce the test duration.');
            return $('input.save_test_case').addClass('hidden');
          } else {
             $('input.save_test_case').removeClass('hidden');
          }
        } else {
           $('input.save_test_case').removeClass('hidden');
        }
}

const SetTestDurationsUpdate = function () {
    let duration_min = 0;
    $('.ivr_holder:visible').each(function() {
        const value = parseInt($(this).data('duration'),10);

        if (value > duration_min) {
           duration_min = value;
        }
    });

    $('#test_case_max_call_time').trigger('configure',
            {
                min : duration_min
            }
    );
    $('#test_case_test_duration').trigger('configure',
        {
            min : duration_min
        }
    );
    $('#test_case_test_duration').data("min", duration_min);
    if (parseInt($('#test_case_max_call_time').val(),10) < duration_min) {
      $('#test_case_max_call_time').val(duration_min).trigger('change');
    }
    if (parseInt($('#test_case_test_duration').val(),10) < duration_min) {
       $('#test_case_test_duration').val(duration_min).trigger('change');
    }
};

$(document).on('turbo:load', function() {

    Maximums();
    UpdateFrequencyUpdate();
    $('#holder_for_ddis').on('change', '.ivr_ddi_select', () => UpdateFrequencyUpdate());
    $('#test_case_repeat_frequency').change(() => UpdateFrequencyUpdate());
    $('#test_case_test_duration').change(() => UpdateFrequencyUpdate());
    $('#test_case_max_concurrent_calls').change(() => UpdateFrequencyUpdate());



    $('#add-ivr-step').on('click',  function() {
      let disable_ivr_scripts = 0;
      const ivr_steps = $('#ivr-steps .nested_test_case_ivr_script_ivr_steps');

      $.each(ivr_steps, function(id, elt) {
        if(elt.style.display === 'none') {
           disable_ivr_scripts = disable_ivr_scripts + 1;
        }
      });

      const ivr_steps_count = Math.abs(disable_ivr_scripts - ivr_steps.length);

      if (ivr_steps_count < 2) {
        $('#add-ivr-step').removeClass('hidden');
      } else {
        $('#add-ivr-step').addClass('hidden');
      }
    });

    $('#ivr-steps').on('click', '.remove-ivr-step', function() {
      let disable_ivr_scripts = 0;
      const ivr_steps = $('#ivr-steps .nested_test_case_ivr_script_ivr_steps');

      $.each(ivr_steps, function(id, elt) {
        if(elt.style.display === 'none') {
           disable_ivr_scripts = disable_ivr_scripts + 1;
        }
      });

      const ivr_steps_count = Math.abs(disable_ivr_scripts - ivr_steps.length);

      if (ivr_steps_count <= 3) {
         $('#add-ivr-step').removeClass('hidden');
      }
    });

    $('#test_case_destinations').on('keydown', function(event) {
      if ((event.keyCode === 13) || (event.which === 13)) {
        const text = $('#test_case_destinations').val();
        const str = text + "\n";
         $('#test_case_destinations').val(str);
      }
    });

  

    $('#ivr_wrapper').on('click', '#outbound_test_test_validations_attributes_0_selected_ddis',  function() {
        if ($("#outbound_test_test_validations_attributes_0_selected_ddis").length > 1) {
            $(this).removeAttr("selected");
             alert('You can select 1 DID');
          }
    });

    //fax service chosen
    $('#fax_service_true').click(function() {
      $('.remove_nested_fields_link').trigger('click');
      $("#test_case_test_duration").val(10).trigger('change');
      $("#test_case_max_call_time").val(10).trigger('change');
      $("#test_case_success_average_mos").val(0).trigger('change');
      $("#test_case_max_ring_time").val(60).trigger('change');
       $('#ivr_wrapper').addClass('hidden');
    });

    $('#fax_service_false').click(() => $('#ivr_wrapper').removeClass('hidden'));


    // Check max concurrent calls is never more than allowed
    $('#ivr_wrapper').on('blur','.call_dist_input', function() {
            const current_value = parseInt($(this).val(),10);
            const max_allowed_channels = parseInt($('#max_concurrent_calls').val(),10);
            let current_total = 0;
            $('#ivr_wrapper').find('.call_dist_input').each(function() {
                const value = parseInt($(this).val(),10) || 0;
                return current_total = current_total + value;
            });
            current_total -= current_value;
            const max_allowed = max_allowed_channels - current_total;
            $(this).attr('max', max_allowed);
            if (current_value < max_allowed) {
                $(this).val(current_value);
            } else {
                if (max_allowed > 0) {
                    $(this).val(max_allowed);
                } else {
                    $(this).val(0);
                  }
              }
            const round_robin = $(this).parent().find('.round_robin_checkbox');
       
            if ($(this).val() === "0") {
                 $(round_robin).prop('checked', true);
                 console.log( $(round_robin).prop('checked'))
            } else {
                 $(round_robin).prop('checked', false);
              }

    });

    $('.remote_call').click(function() {
      $('#test_case_details_div').html('<div class="small-loader"></div>');
      $('.remote_call').removeClass('info');
       $(this).addClass('info');
    });

    if ($('#test_case_test_type').val() === '2') {
      $('#frequency-holder').removeClass('hidden');
      $('#retry-dial').removeClass('hidden');
    } else if ($('#test_case_test_type').val() === '3') {
      $('.call_distribution').removeClass('hidden');
    }


    $('#test_case_test_type').change(function() {
      Maximums();
      if ($(this).val() === '2') {
        // Monitoring test
        $('#frequency-holder').removeClass("hidden");
        $('#retry-dial').removeClass('hidden');
        $('.call_distribution').addClass("hidden");
        if ($('option[value="IN COUNTRY"]', $('.protocol_selector')) .length === 0) {
           $('.protocol_selector').append("<option value='IN COUNTRY'>IN COUNTRY</option>");
        }
      } else if ($(this).val() === '1') {
        $('#retry-dial').addClass('hidden');
        $('#frequency-holder').addClass('hidden');
        $('.call_distribution').addClass('hidden');
        if ($('option[value="IN COUNTRY"]', $('.protocol_selector')) .length === 0) {
          $('.protocol_selector').append("<option value='IN COUNTRY'>IN COUNTRY</option>");
        }
        $('#country_selector').val('');
         $('#test_case_breakout_id').removeClass('hidden');
      } else if ($(this).val() === '3') {
        $('#retry-dial').addClass('hidden');
        $('#frequency-holder').addClass('hidden');
        $('.call_distribution').removeClass('hidden');
        $('#incountry_selector').addClass('hidden');
        $('option[value="IN COUNTRY"]', $('.protocol_selector')).removeClass('hidden');
        $('#country_selector').val('');
         $('#test_case_breakout_id').val('');
      }
    });
    $('#virtual_agent_yes').click(() => $('#virtual-agent-holder').removeClass('hidden'));
    $('#virtual_agent_no').click(() => $('#virtual-agent-holder').addClass('hidden'));


    if ($('select.protocol_selector').val() === "SIP") {
      $('#endpoint_selector').removeClass('hidden');
    } else if ($('select.protocol_selector').val() === "IN COUNTRY") {
      $('#incountry_selector').removeClass('hidden');
      $('#test_case_source_ddi').prop('disabled', true);
    }


    //# Check and disable endpoint if PSTN chosen
    $('body').on('change', 'select.protocol_selector', function() {
      let country_code;
      if ($('select.protocol_selector').val() === "PSTN") {
        $('#endpoint_selector').addClass("hidden");
        $('#incountry_selector').addClass("hidden");
        $('#test_case_breakout_id').val("");
        $('#country_selector').val("");
        $('#test_case_endpoint').val("");
        $('#test_case_source_ddi').addClass('disabled', false);
      } else if ($('select.protocol_selector').val() === "SIP") {
        $('#endpoint_selector').removeClass("hidden");
        $('#test_case_source_ddi').prop('disabled', false);
        $('#incountry_selector').addClass("hidden");
        $('#test_case_breakout_id').val("");
        $('#country_selector').val("");
        $('#test_case_endpoint').val("");
      } else if ($('select.protocol_selector').val() === "IN COUNTRY") {
        $('#endpoint_selector').addClass("hidden");
        $('.ivr_ddi_select').html('').multiSelect('refresh');
        $('.remove_nested_fields_link').trigger('click');
        $('#incountry_selector').removeClass("hidden");
        $('#test_case_source_ddi').prop('disabled', true);
        $('#test_case_endpoint').val("");
      }
      Maximums();
      if ($('#country_selector').val() === '') { 
        country_code =  0; 
      } else { 
        country_code = $('#country_selector').val();
      }
    });


    // DDIs Selection functions
    $('#holder_for_ddis').on('change', '.selected_range', function() {
      if ($(this).val() === '') {
        return
      }
      const selector = $(this);
      $(selector).addClass("hidden")
      $(selector).parent().find('.small-loader').removeClass("hidden");
       $.ajax('/control_centre/test_ddi_ranges/'+ $(this).val() + ".json?", {
              type: 'GET',
              dataType: 'json',
              contentType: "application/json",
              data: {
              },
              error(jqXHR, textStatus, errorThrown) {
                   console.log(`AJAX Error: ${textStatus}`);
                },
              success(response) {
                  const options = $(selector).closest('.ddi_holder').find('.ivr_ddi_select option');
                  const values = $.map(options, e => $(e).val())
                  const optgroup = $('<optgroup>');
                  optgroup.attr('label',response.name);
                  $.each(response.ddis, function(index, ddi){
                    if (!values.includes(ddi.id.toString())) {
                      const option = $("<option></option>");
                      option.val(ddi.id);
                      option.text(ddi.name);
                      optgroup.append(option);
                    }
                  });
                  const multiChoice = $(selector).closest('.ddi_holder').find('.ivr_ddi_select');
                  $(multiChoice).append(optgroup);
                  $(multiChoice).multiSelect('refresh');
                  const range_list = $(selector).closest('.ddi_holder').find('.selected_ranges_list');
                  range_list.val(range_list.val() + ',' + response.id);
                  $(selector).val('');
                  $(selector).parent().find('.small-loader').addClass('hidden');
                   $(selector).removeClass('hidden');
                }
            });
    });
    //################################



    //################################
    // Bug fix for form template left in after dynamic field in
    $('#test_case_form_holder form').submit(function(e) {
      e.preventDefault();
      $(".form_template").addClass('hidden');
      this.submit();
      return false;
    });
    //################################


 


    // If the cancel button is showing start polling for results.
    if ($('#cancel_test').removeClass("hidden")) {
        // ResultsPollerRequest();
      }

    if ($('#pre_check_div').removeClass("hidden")) {
        PreCheckPollerRequest();
    }


    // Used on Index to show different test types
    $('#test-cases-holder').on('click', '.type-selector',  () => $('#loading_div').removeClass('hidden'));



    // STT an Alert message
    $('#test_case_form_holder').on('change', '#alert_call_text',e => $('#alert_call_audio_id').val(""));




    




    $('#ivr_wrapper').on('change', '.call_party_select', function(e) {
      const sip_opt = $(this).parent().parent().parent().find('.sip_options');
      const ddi = $(this).parent().parent().parent().find('.ddi_selection_div');
      const form = $(this).parent().parent().parent().find('.sip_option_form');
      if ($(this).val() === '1') {
          sip_opt.removeClass('hidden');
          ddi.addClass('hidden');
      } else {
          sip_opt.addClass('hidden');
          $(this).parent().parent().parent().find('.sip_options_toggle').prop('checked', false);
          form.addClass('hidden');
          ddi.removeClass('hidden');
        }
      });





    // # Callbacks when a new IVR validation is added
    $('#ivr_wrapper').on('fields_added.nested_form_fields', function(event, param) {
        
        let ivr_id;
        const ivr_holder = event.target;
        if ($(ivr_holder).hasClass('nested_validations') || $(ivr_holder).hasClass('nested_validations')) { 
          UpdateTestDDISelect();
          SourceDdiRemove();
          UpdateAgentSelect();
          let ivr_name = $('.selected_ivr option:selected').text();
          ivr_id = $('.selected_ivr').val();
          // Filter dropdown for ddi range

          if (ivr_id === '') {
            ivr_id = $(ivr_holder).parent().parent().find('.ivr_script_id_hidden').val();
            ivr_name = $(ivr_holder).parent().parent().find('h4.ivr_name').text();
          }
          if ($('#test_case_test_type').val() === '3') {
            $('.call_distribution').removeClass('hidden');
          }
          $('.ivr_holder:last').find('h4.ivr_name').text(ivr_name);
          $('.ivr_holder:last').find('.ivr_script_id_hidden').val(ivr_id);
          $("#test_case_test_ddi_range_ids").multiSelect('deselect_all');
          $('#outbound_test_inbound_ddi_id').val('');
          $('#outbound_ivr_selector_holder').addClass('hidden');
          $('#ddi_selector').addClass('hidden');
          $('.multiple-outbound').multiSelect({
            afterSelect(values) {
              if ($('.multiple-outbound').val().length > 1) {
                $('.multiple-outbound').multiSelect('deselect',values);
                alert('Only one number can be selected, please deselect the existing entry.');
              }
            }
          });
        }

        // Event handler for adding and removing step validations
        $('div.step_validations').on('fields_added.nested_form_fields', function(event, param) {
            const step_holder = event.target;
            $(step_holder).find('input.ivr_step_id_hidden_field').val(param.additional_data["ivr_step_id"]);
            console.log(  $(step_holder).find('input.ivr_step_id_hidden_field').val())
            console.log(  $(step_holder).find('input.ivr_step_id_hidden_field').val)
            $( step_holder).find('input.audio_file_id_hidden_field').val(param.additional_data["audio_file_id"]);
            if (param.additional_data["agent_action"] === true) {
              $('#latency-dial').removeClass('hidden');
              const agent_holder = $(step_holder).parent().parent().parent().parent().find('div.virtual-agent-holder');
              agent_holder.removeClass('hidden');
            }
        });

        if ($(ivr_holder).hasClass('nested_test_case_test_validations') || $(ivr_holder).hasClass('nested_outbound_test_test_validations')) { 
          return $.ajax('/test_cases/add_ivr_validation_steps/'+ ivr_id + ".json?" + (new Date).getTime(), {
                  type: 'GET',
                  dataType: 'json',
                  contentType: "application/json",
                  data: {

                  },
                  error(jqXHR, textStatus, errorThrown) {},
                  success(response) {
                      if ($('#test_case_country_id').removeClass("hidden") && (response.duration > 120)) {
                          $('#ivr_wrapper').find("a.remove-ivr-validation").trigger('click');
                          alert("Your IVR Script is too long for In-Country testing.. Sorry!");
                          return;
                        }
                      // Assign holder for duration to work out min max call durations
                      $('.ivr_holder:last').data("duration", response.duration);
                      
                      SetTestDurationsUpdate();

                      $.each(response.items, (index, item) => $(ivr_holder).find("a.add_validation_steps_button").trigger( "click", item));
                      
                      return $(ivr_holder).find("a.add_validation_steps_button").addClass('hidden');
                    }
                },
                  ($(".remove_post_validation:visible").length) ?
                    $('html,body').animate({scrollTop: $('.remove_post_validation:last').offset().top - 200},'slow')
                  :
                    $('html,body').animate({scrollTop: $('.ivr_name:last').offset().top - 150},'slow')
          );
        }
    });

    // Deal with IVR Scripts being removed from validation.
    $('#ivr_wrapper').on('fields_removed.nested_form_fields', function(event, param) {
        $(event.target).find('.source_ddi_field').attr("required", false);
        if (!($('#ivr_wrapper').find('a.remove_nested_fields_link').removeClass("hidden"))) {
            $('#ddi_selector').removeClass('hidden');
          }
        $('#outbound_ivr_selector_holder').removeClass('hidden');
        SetTestDurationsUpdate();
        SourceDdiAdd();
    });

 

   

  

    // return $('.bot_show').click(function(e) { 
    //   e.preventDefault();
    //   return $('#results').toggle();
    // });
});