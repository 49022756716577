import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="audio-links"
export default class extends Controller {
  initialize() {
    this.template = this.element.getAttribute('data-template')
  }

  show(event) {
    const html = this.template.replace(/#id#|%23id%23/g, event.detail.value)
    this.element.innerHTML = html
  }

  clear(event) {
    if (event.detail.token.classList.contains('active')) {
      this.element.innerHTML = ''
    }
  }
}
