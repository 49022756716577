import { Controller } from "@hotwired/stimulus";

// Use data-controller="multi-selector--multi-selector"
export default class extends Controller {
  static targets = ['container']

  connect() {
    if (!this.hasContainerTarget) {
      console.log("MISSING CONTAINER")
      return 
    }

    const filter = this.element.dataset.filter
    const optGroupAllowed = this.element.dataset.optgroup
    $(this.containerTarget).multiSelect({
      selectableOptgroup: optGroupAllowed,
      selectableHeader: `<input type='text' class='search-input' autocomplete='off' placeholder='Filter By ${filter}'>`,
      selectionHeader: `<input type='text' class='search-input' autocomplete='off' placeholder='Filter By ${filter}'>`,
      afterInit: function(ms){
        var that = this,
            $selectableSearch = that.$selectableUl.prev(),
            $selectionSearch = that.$selectionUl.prev(),
            selectableSearchString = '#'+that.$container.attr('id')+' .ms-elem-selectable:not(.ms-selected)',
            selectionSearchString = '#'+that.$container.attr('id')+' .ms-elem-selection.ms-selected';
    
        that.qs1 = $selectableSearch.quicksearch(selectableSearchString)
        .on('keydown', function(e){
          if (e.which === 40){
            that.$selectableUl.focus();
            return false;
          }
        });
    
        that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
        .on('keydown', function(e){
          if (e.which == 40){
            that.$selectionUl.focus();
            return false;
          }
        });
      },
      afterSelect: function(){
        this.qs1.cache();
        this.qs2.cache();
      },
      afterDeselect: function(){
        this.qs1.cache();
        this.qs2.cache();
      }
    });
  }

  addAll(e) {
    $(this.containerTarget).multiSelect('select_all')
  }


  removeAll(e) {
    $(this.containerTarget).multiSelect('deselect_all')
  }
}