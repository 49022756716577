/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbo:load', function() {
  $(".play_audio_link").each((index, element) => {
    const audio_id = $(element).parent().find('select.audio_file').val();
    if (audio_id != null) {
      $(element).attr("id", audio_id);
      return $(element).attr("href", '/play_audio/' + audio_id);
    }
  });
  $('body').on('click','i.fi-quote.text-to-speech-btn', function() {
    $('select.audio_file').removeClass('active');
    $('.play_audio_link').removeClass('active');
    $('i.fi-quote.text-to-speech-btn').removeClass('active');
    $(this).addClass('active');
    $(this).parent().parent().find('select.audio_file').addClass('active');
    $(this).parent().parent().find('.play_audio_link').addClass('active');
    $('#ivr_step_audio_file_id').addClass('active');
    return $('#utterance_audio_file_id').addClass('active');
  });
  $('body').on('click', '.audio-recorder-btn', function() {
    $(this).parent().parent().find('select.audio_file').addClass('active');
    $(this).parent().parent().find('.play_audio_link').addClass('active');
    $('#ivr_step_audio_file_id').addClass('active');
    $('#utterance_audio_file_id').addClass('active');
  });
});
